import {useEffect} from "react";
import {useNavigate} from "react-router-dom";


export const SignOut = () => {

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.clear()
        navigate("/sign-in")
    }, []);

    return (<></>)
}