import Header from "@/components/layout/header";
import Sidebar from "@/components/layout/sidebar";
import AdminRoutes from "@/routes/admin-routes";
import PublicRoutes from "@/routes/public-routes";
import GeneralRoutes from "@/routes/general-routes";

export default function MainLayout({
                                       children, isAdmin=false
                                   }: {
    children: React.ReactNode; isAdmin?: boolean;
}) {
    // Determine the correct route component based on user roles/authentication
    let RoutesComponent;
    if (isAdmin) {
        RoutesComponent = AdminRoutes
    }else {
        RoutesComponent = GeneralRoutes; // Routes for unauthenticated users
    }

    return (
        <RoutesComponent>
            <div className="flex flex-col h-screen">
                {/* Header */}
                <Header />
                <div className="flex flex-1">
                    {/* Sidebar */}
                    <div className="fixed left-0 h-full">
                        <Sidebar />
                    </div>
                    {/* Main Content */}
                    <main
                        className={`flex-1 overflow-y-auto md:ml-44 lg:ml-44`}
                    >
                        {children}
                    </main>
                </div>
            </div>
        </RoutesComponent>
    );
}
