// @ts-nocheck

import React from 'react';
import {
    ColumnDef,
    SortingState,
    flexRender,
    useReactTable, getCoreRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DataTablePagination } from './data-table-pagination';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {LoadingSpinner} from "@/components/loader/spinner";

interface DataTableProps {
  columns: ColumnDef<any>[];
  data: any[];
  isLoading: boolean;
  pagination: {
    page: number;
    limit: number;
    total: number;
    onPageChange: (page: number) => void;
    onLimitChange: (limit: number) => void;
  };
  onSortingChange: (sorting: SortingState) => void
}

export function GlassDatabaseTable({
                             columns,
                             data,
                             isLoading,
                             pagination,
                             onSortingChange,
                           }: DataTableProps) {

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: [],
    },
    onSortingChange,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(pagination.total / pagination.limit),
    getCoreRowModel: getCoreRowModel(), // Required for basic table functionality
  });

  return (
      <div className="space-y-4">
        <ScrollArea className="rounded-md border h-[calc(80vh-220px)]">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup, headerGroupIndex) => (
                  <TableRow key={headerGroupIndex}>
                    {headerGroup.headers.map((header, headerIndex) => (
                        <TableHead
                            key={headerIndex}
                        >
                            <div
                                className={`flex flex-col items-center w-full font-bold`}
                                style={{whiteSpace: 'nowrap'}} // Ensure no wrapping
                            >
                                <span>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                  </span>
                            </div>
                        </TableHead>
                    ))}
                  </TableRow>

              ))}
            </TableHeader>
              <TableBody>
                  {isLoading ? (
                      <TableRow>
                          <TableCell colSpan={columns.length} className="h-24 text-center">
                              Loading...
                          </TableCell>
                      </TableRow>
                  ) : table.getRowModel().rows.length ? (
                      table.getRowModel().rows.map((row, rowIndex) => (
                          <TableRow key={rowIndex}>
                              {row.getVisibleCells().map((cell, cellId) => (
                                  <TableCell key={cellId}>
                                      <div
                                          className={`flex flex-col items-center w-full font-light`}
                                          style={{whiteSpace: 'nowrap'}} // Ensure no wrapping
                                      >
                                        <span>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                          </span>
                                      </div>

                                  </TableCell>

                              ))}
                          </TableRow>
                      ))
                  ) : (
                      <TableRow>
                          <TableCell colSpan={columns.length} className="h-24 text-center">
                              <div className="flex flex-row items-center justify-center w-full gap-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                       fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                       stroke-linejoin="round"
                                       className="icon icon-tabler icons-tabler-outline icon-tabler-dog">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                      <path d="M11 5h2"/>
                                      <path d="M19 12c-.667 5.333 -2.333 8 -5 8h-4c-2.667 0 -4.333 -2.667 -5 -8"/>
                                      <path d="M11 16c0 .667 .333 1 1 1s1 -.333 1 -1h-2z"/>
                                      <path d="M12 18v2"/>
                                      <path d="M10 11v.01"/>
                                      <path d="M14 11v.01"/>
                                      <path
                                          d="M5 4l6 .97l-6.238 6.688a1.021 1.021 0 0 1 -1.41 .111a.953 .953 0 0 1 -.327 -.954l1.975 -6.815z"/>
                                      <path
                                          d="M19 4l-6 .97l6.238 6.688c.358 .408 .989 .458 1.41 .111a.953 .953 0 0 0 .327 -.954l-1.975 -6.815z"/>
                                  </svg>
                                  No data found.
                              </div>
                          </TableCell>
                      </TableRow>
                  )}
              </TableBody>
          </Table>
            <ScrollBar orientation="horizontal"/>
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-10">
                    <LoadingSpinner/>
                </div>
            )}
        </ScrollArea>
          <DataTablePagination
              table={table}
              onPageChange={(pageIndex) => pagination.onPageChange(pageIndex + 1)} // Adjust for 1-based index
              onLimitChange={(pageSize) => pagination.onLimitChange(pageSize)}
              totalRows={pagination.total}
              pageSize={pagination.limit}
          />
      </div>
  );
}
