import {
    Card,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import React, {useEffect, useState} from "react";
import { CardContent } from "@mui/material";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {usePyServiceManager} from "@/hooks/use-pyservice";
import {toast} from "@/hooks/use-toast";
import {generateDynamicColumns} from "@/pages/general/ml-models/components/glassnet-columns";
import {GlassNetModelDataTable} from "@/pages/general/ml-models/components/glassnet-table";
import {IconDownload} from "@tabler/icons-react";
import {Cross2Icon, PlusCircledIcon} from "@radix-ui/react-icons";

export function MlModelsTabs() {
    const [components, setComponents] = useState([]);
    const [currentComponent, setCurrentComponent] = useState({ name: "", from: "", to: "" });
    const [formattedCompositions, setFormattedCompositions] = useState([]);

    const description = (
        <>
            GlassNet is a powerful tool for predicting glass properties based on compositional ranges.
            To learn more about the model and its applications,
            visit the{" "}
            <a
                href="https://glasspy.readthedocs.io/en/latest/intro/notebooks/glasspy_predict.html"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-blue-500 text-blue-400"
            >
                GlassNet Model documentation
            </a>
            .
            <p className="text-xs text-gray-500 mt-2">
                *Note: It generates maximum of 100 composition using even spacing based on the composition ranges you provide, we create multiple possible combinations of values for each component. This allows us to predict and analyze various scenarios within the specified ranges.
            </p>
        </>
    );

    const { data: glassNetData, isLoading, isError } = usePyServiceManager().useFetchGlassNetModel(formattedCompositions);

    const handleAddComponent = () => {
        if (currentComponent.name && currentComponent.from && currentComponent.to) {
            setComponents([...components, currentComponent]);
            setCurrentComponent({ name: "", from: "", to: "" });
        }
    };

    const handleRemoveComponent = (index) => {
        setComponents(components.filter((_, i) => i !== index));
    };

    const handlePredictClick = () => {
        const formatted = components.map((comp) => ({
            name: comp.name,
            range: [parseFloat(comp.from), parseFloat(comp.to)],
        }));
        setFormattedCompositions(formatted);
    };

    const [predictedData, setPredictedData] = useState([]);
    const transformDataForTable = (data: any[]): any[] => {

        const rows: any[] = [];

        data.forEach((item) => {
            const row: { composition: string; [key: string]: any } = {
                composition: JSON.stringify(item.composition), // Convert composition to string for display
            };
            Object.keys(item.predictions).forEach((key) => {
                row[key] = item.predictions[key][0]; // Extract the prediction value
            });
            rows.push(row);
        });

        return rows;
    };

    // Utility function to convert JSON to CSV
    const convertToCSV = (data: any[]) => {
        if (!data.length) return "";

        const headers = Object.keys(data[0]).join(","); // Extract headers
        const rows = data.map((row) =>
            Object.values(row)
                .map((value) => `"${value}"`) // Escape values
                .join(","),
        );

        return [headers, ...rows].join("\n");
    };

    // Handle CSV download
    const handleDownload = () => {
        const csvData = convertToCSV(predictedData);
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });


        // Generate a timestamp
        const timestamp = new Date().toISOString().replace(/[:.]/g, "-"); // Replaces ':' and '.' with '-' to make it filename-safe
        const fileName = `GlassNetModelPredictionData_${timestamp}.csv`;

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        if (isError){
            toast({
                variant: "destructive",
                title: "GlassNet Model Error.",
                description:`Failed to fetch the data`,
            });
            setPredictedData([]);
        }

        if (glassNetData && glassNetData.data) {
            setPredictedData(transformDataForTable(glassNetData.data));
        }

    }, [isError, glassNetData])


    return (
        <Tabs defaultValue={"glassnet"}>
            <TabsList className="grid w-full grid-cols-8">
                <TabsTrigger value="glassnet">GlassNet</TabsTrigger>
            </TabsList>
            <TabsContent value="glassnet" className="w-full">
                <Card>
                    <CardHeader>
                        <CardTitle>AI Model</CardTitle>
                        <CardDescription>{description}</CardDescription>
                    </CardHeader>
                    <CardContent className="">
                        <div className="flex flex-col gap-4">
                            <div>
                                <p className="text-sm font-semibold mb-2">Selected Components:</p>
                                {components.length === 0 ? (
                                    <p className="text-sm text-gray-500">No components added.</p>
                                ) : (
                                    <div className="flex flex-wrap gap-4">
                                        {components.map((comp, index) => (
                                            <div key={index} className="flex gap-2 items-center text-sm border p-2 rounded-md">
                                                <span>{comp.name}</span>
                                                <span>({comp.from} - {comp.to})</span>
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleRemoveComponent(index)}
                                                    className="h-4 w-4"
                                                >
                                                    <Cross2Icon className="h-4 w-4" />
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="flex items-center gap-2">
                                <Input
                                    placeholder="component"
                                    value={currentComponent.name}
                                    onChange={(e) =>
                                        setCurrentComponent({ ...currentComponent, name: e.target.value })
                                    }
                                    className="h-8 w-[100px] lg:w-[150px]"
                                />
                                <Input
                                    placeholder="from"
                                    type="number"
                                    value={currentComponent.from}
                                    onChange={(e) =>
                                        setCurrentComponent({ ...currentComponent, from: e.target.value })
                                    }
                                    className="h-8 w-[100px] lg:w-[150px]"
                                />
                                <Input
                                    placeholder="to"
                                    type="number"
                                    value={currentComponent.to}
                                    onChange={(e) =>
                                        setCurrentComponent({ ...currentComponent, to: e.target.value })
                                    }
                                    className="h-8 w-[100px] lg:w-[150px]"
                                />
                                <Button
                                    variant="outline"
                                    size="sm"
                                    className="h-8 border-dashed h-8 px-2 lg:px-3"
                                    onClick={handleAddComponent}
                                >
                                    <PlusCircledIcon className="h-4 w-4" />
                                    Add
                                </Button>
                                <Button
                                    onClick={handlePredictClick}
                                    className="h-8 px-4"
                                    disabled={components.length === 0}
                                >
                                    {!isLoading? "Predict":" Loading..."}
                                </Button>
                                {components.length > 0 && (
                                    <Button
                                        variant="ghost"
                                        className="h-8 px-2 lg:px-3"
                                        onClick={() => setComponents([])}
                                    >
                                        Reset
                                        <Cross2Icon className="ml-2 h-4 w-4" />
                                    </Button>
                                )}
                            </div>

                            {
                                predictedData && predictedData.length > 0 && (
                                    <div className='mt-6'>
                                        <div className="flex flex-row justify-between items-center gap-2 w-full">
                                            <h3 className='text-lg font-bold mb-2'>Predicted Data:</h3>
                                            {predictedData && predictedData.length > 0 && (
                                                <Button
                                                    variant='outline'
                                                    className="h-8 px-2 lg:px-3"
                                                    onClick={handleDownload}
                                                >
                                                    <span>Download</span> <IconDownload size={18}/>
                                                </Button>
                                            )}
                                        </div>
                                        <GlassNetModelDataTable
                                            data={predictedData}
                                            columns={generateDynamicColumns(predictedData)}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </CardContent>
                </Card>
            </TabsContent>
        </Tabs>
    );
}
