import React, {useEffect, useRef, useState} from "react";
import BreadCrumb from "@/components/breadcrumb";
import PeriodicTable from "@/pages/general/glass-database-view/components/periodic-table";
import {Main} from "@/components/layout/main";
import {GlassDatabaseFilters} from "@/pages/general/glass-database-view/components/glass-database-filters";
import {useDatabaseManager} from "@/hooks/use-database-hook";
import {GlassDatabaseTabs} from "@/pages/general/glass-database-view/components/glass-database-tabs";

const breadcrumbItems = [
    { title: "Glass Database", link: "/dashboard/glass-database" },
];


interface RangeFilter {
    min: any;
    max: any;
    inclusive: boolean;
}

interface Filter {
    key: string;
    operation: string;
    value?: any;
    range?: RangeFilter;
}

interface FetchDataRequest {
    filters: {
        compositions: Filter[];
        properties: Filter[];
    };
    databaseID: number;
}

export default function GlassDatabaseView() {

    const [glassDatabaseID, setGlassDatabaseID] = useState(0);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(20);
    const [filters, setFilters] = useState<FetchDataRequest['filters']>({
        compositions: [],
        properties: [],
    });
    const [sorting, setSorting] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [oxideKey, setOxideKey] = useState('');
    const [propertyKey, setPropertyKey] = useState('');
    const [xAxis, setXAxis] = useState('');
    const [yAxis, setYAxis] = useState('');
    const [binSize, setBinSize] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const  oxideFilterRef = useRef(null);
    const handleScrollToOxideComponent = () => {
        if (oxideFilterRef.current) {
            oxideFilterRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    const  glassDatabaseViewTableRef = useRef(null);
    const handleGlassDatabaseViewTableRef = () => {
        if (glassDatabaseViewTableRef.current) {
            glassDatabaseViewTableRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };


    const {data: databaseList} = useDatabaseManager().useFetchDatabase(Number(localStorage.getItem("id")));
    const {data: databaseData, isFetching: isDataFetching} = useDatabaseManager().useFetchDatabaseData({database_id: glassDatabaseID, page: page, limit: limit, meta_data_search_key: searchKey, sorting, filters: filters});
    const {data: databaseGraphData, isFetching: isGraphDataFetching, error: graphDataError} = useDatabaseManager().useFetchDatabaseDataByBinSize({database_id: glassDatabaseID, filters: filters, bin_size: binSize, x_axis: xAxis, y_axis: yAxis});
    const { data: databaseColumns } = useDatabaseManager().useFetchDatabaseColumns(glassDatabaseID);
    const {data: databaseOxides} = useDatabaseManager().useFetchDatabaseOxides(glassDatabaseID);
    const {data: databaseProperties} = useDatabaseManager().useFetchDatabaseProperties(glassDatabaseID);
    const {data: databaseElements, isFetching: isDatabaseElementsFetching} = useDatabaseManager().useFetchDatabaseElements(glassDatabaseID);
    const {data: databaseDataDownloadURL, isFetching: isDatabaseDataDownloadURLFetching, refetch: refetchDatabaseDownload} = useDatabaseManager().useFetchDatabaseDataDownloadURL({database_id: glassDatabaseID, page: page, limit: limit, meta_data_search_key: searchKey, sorting, filters: filters, totalRows: totalRows})

    const {data: graphOxides} = useDatabaseManager().useFetchGraphOxides(glassDatabaseID);
    const {data: graphProperties} = useDatabaseManager().useFetchGraphProperties(glassDatabaseID);
    useEffect(() => {
        if (databaseList && databaseList.data && Array.isArray(databaseList.data) && databaseList.data.length > 0) {
            setGlassDatabaseID(databaseList.data[0].id);
        }
    }, [databaseList]);

    useEffect(() => {
        setTotalRows(databaseData?.data?.pagination?.total_items ?? 0)
    }, [databaseData]);


    return (
        <Main>
            <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
                <BreadCrumb items={breadcrumbItems}/>
            </div>
            <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap'>
                <div>
                    <h2 className='text-2xl font-bold tracking-tight'>Glass Database</h2>
                    <p className='text-muted-foreground'>
                        Explore all the available glass databases here.
                    </p>
                </div>
                <div className="flex items-center justify-end">
                    <select
                        className="capitalize border rounded-md px-2 py-1.5"
                        onChange={(e) => setGlassDatabaseID(Number(e.target.value))}
                    >
                        {databaseList && databaseList.data && Array.isArray(databaseList.data) && (
                            databaseList.data.map((db) => (
                                <option key={db.id} value={db.id} style={{fontWeight: '300'}}>
                                    {db.name}
                                </option>
                            ))
                        )}
                    </select>
                </div>
            </div>
            <div className="flex items-center justify-center gap-2 flex-wrap w-full">
                <PeriodicTable
                    databaseElements={databaseElements?.data ?? []}
                    databaseOxides={databaseOxides?.data ?? []}
                    setOxideKey={setOxideKey}
                    handleScrollToOxideComponent={handleScrollToOxideComponent}
                    isDatabaseElementsFetching={isDatabaseElementsFetching}
                />
            </div>
            <GlassDatabaseFilters
                setFilters={setFilters}
                filters={filters}
                databaseOxides={databaseOxides?.data ?? []}
                databaseProperties={databaseProperties?.data ?? []}
                compositionKey={oxideKey}
                setCompositionKey={setOxideKey}
                propertyKey={propertyKey}
                setPropertyKey={setPropertyKey}
                oxideFilterRef={oxideFilterRef}
            />
            <GlassDatabaseTabs
                databaseColumns={databaseColumns}
                databaseData={databaseData}
                isDataFetching={isDataFetching}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                setSorting={setSorting}
                setSearchKey={setSearchKey}
                searchKey={searchKey}
                glassDatabaseViewTableRef={glassDatabaseViewTableRef}
                handleGlassDatabaseViewTableRef={handleGlassDatabaseViewTableRef}
                properties={graphProperties?.data ?? []}
                oxides={graphOxides?.data ?? []}
                xAxis={xAxis}
                yAxis={yAxis}
                setXAxis={setXAxis}
                setYAxis={setYAxis}
                binSize={binSize}
                setBinSize={setBinSize}
                databaseGraphData={databaseGraphData?.data?.items ?? []}
                totalPoints={databaseGraphData?.data?.pagination?.total_items ?? 0}
                isGraphDataFetching={isGraphDataFetching}
                graphDataError={graphDataError}
                isFetchingDownloadURL={isDatabaseDataDownloadURLFetching}
                databaseDataDownloadURL={databaseDataDownloadURL?.data.download_url ?? ""}
                handleDownload={refetchDatabaseDownload}
            />
        </Main>


    );
}
