import { Main } from '@/components/layout/main';
import BreadCrumb from '@/components/breadcrumb';
import {MlModelsTabs} from "@/pages/general/ml-models/components/ml-models-tabs";

const breadcrumbItems = [{ title: 'ML Models', link: '/dashboard/ml-models' }];

export default function MlModels() {
    return (
        <Main>
            <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
                <BreadCrumb items={breadcrumbItems}/>
            </div>
            <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap'>
                <div>
                    <h2 className='text-2xl font-bold tracking-tight'>ML Models</h2>
                    <p className='text-muted-foreground'>
                        Try out all available models here.
                    </p>
                </div>
            </div>
            <MlModelsTabs />
        </Main>
    );
}
