
import {statuses} from '../data/data'
import { DataTableColumnHeader } from './data-table-column-header'


export const columns = () => [
  {
    accessorKey: 'id',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title='Job ID' />
    ),
    cell: ({ row }) => (
      <div className='w-fit text-nowrap'>{row.getValue('id')}</div>
    ),
  },
  {
    accessorKey: 'glass_database_name',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Databse Name' />
    ),
    cell: ({ row }) => <div>{row.getValue('glass_database_name')}</div>,
    enableSorting: false,
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Status' />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
          (status) => status.value === row.getValue('status')
      )

      if (!status) {
        return null
      }

      return (
          <div className='flex w-[100px] items-center'>
            {status.icon && (
                <status.icon className={`mr-2 h-5 w-5  ${status.color}`} />
            )}
            <span className="text-muted-foreground">{status.label}</span>
          </div>
      )
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: 'created_at',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Created At' />
    ),
    cell: ({ row }) => {
      const createdAt = new Date(row.getValue('created_at'));
      const formattedDate = createdAt.toLocaleDateString('en-US'); // You can adjust locale and options
      return <div>{formattedDate}</div>;
    },
    enableSorting: false,
  },
  {
    accessorKey: 'object_name',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='File name' />
    ),
    cell: ({ row }) => <div>{row.getValue('object_name')}</div>,
    enableSorting: false,
  },
  {
    accessorKey: 'user_name',
    header: ({ column }) => (
        <DataTableColumnHeader column={column} title='Created by' />
    ),
    cell: ({ row }) => <div>{row.getValue('user_name')}</div>,
    enableSorting: false,
  },

]
