import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

interface Framework {
  label: string;
  value: string;
  min?: number;
  max?: number;
}

interface ComboboxProps {
  value: string;
  setValue: (value: string) => void;
  frameworks?: Framework[];
  placeholder?: string;
  [key: string]: any;
}

export function Combobox({
  frameworks = [],
  value,
  setValue,
  placeholder,
  ...props
}: ComboboxProps) {

  const [open, setOpen] = React.useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between cursor-pointer"
        >
          {value && value.length > 0
            ? frameworks.find((framework) => framework.value === value)?.label
            : <p className="font-normal text-muted-foreground/90">{placeholder}</p>}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-full">
        <Command>
          <CommandInput placeholder={placeholder} className="h-8" />
          <CommandEmpty>No framework found.</CommandEmpty>
          <CommandGroup>
            <CommandList>
              {frameworks?.map((framework) => (
                  <CommandItem
                      key={framework.value}
                      onSelect={() => {
                        setValue(framework.value);
                        setOpen(false);
                      }}
                  >
                    <div className="flex items-center cursor-pointer">
                      <span className="text-sm text-gray-600">{framework.label}</span>
                      {(framework.min !== undefined && framework.max !== undefined) && (
                          <span className="ml-2 text-xs font-light text-muted-foreground/90">
                            ({framework.min} to {framework.max})
                          </span>
                      )}
                    </div>
                    <CheckIcon
                        className={cn(
                            "ml-auto h-4 w-4",
                            value === framework.value ? "opacity-100" : "opacity-0",
                        )}
                    />
                  </CommandItem>
              ))}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
