import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RouteComponentProps {
    children?: ReactNode; // Optional children
}

const GeneralRoutes: React.FC<RouteComponentProps> = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('is_user_logged_in') === 'true';

        if (!isLoggedIn) {
            navigate('/sign-in', { replace: true });
        }
    }, [navigate]);

    return <>{children}</>;
};

export default GeneralRoutes;
