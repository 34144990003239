import * as React from 'react';
import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons';
import { Column } from '@tanstack/react-table';
import { cn } from '@/lib/utils';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Separator } from '@/components/ui/separator';

interface DataTableFacetedFilterProps<TData, TValue> {
  column: Column<TData, TValue>;
  title: string;
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  onFilterChange?: (selectedValues: string[]) => void; // Optional handler for backend
}

export function DataTableFacetedFilter<TData, TValue>({
                                                        column,
                                                        title,
                                                        options,
                                                        onFilterChange,
                                                      }: DataTableFacetedFilterProps<TData, TValue>) {
  const selectedValues = new Set(column.getFilterValue() as string[]);

  const handleSelect = (value: string) => {
    if (selectedValues.has(value)) {
      selectedValues.delete(value);
    } else {
      selectedValues.add(value);
    }

    const filterValues = Array.from(selectedValues);
    column.setFilterValue(filterValues.length ? filterValues : undefined);

    if (onFilterChange) {
      onFilterChange(filterValues); // Notify parent about changes
    }
  };

  const handleClearFilters = () => {
    selectedValues.clear();
    column.setFilterValue(undefined);
    if (onFilterChange) {
      onFilterChange([]);
    }
  };

  return (
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" size="sm" className="h-8 border-dashed">
            <PlusCircledIcon className="h-4 w-4" />
            {title}
            {selectedValues.size > 0 && (
                <>
                  <Separator orientation="vertical" className="mx-2 h-4" />
                  <Badge
                      variant="secondary"
                      className="rounded-sm px-1 font-normal lg:hidden"
                  >
                    {selectedValues.size}
                  </Badge>
                  <div className="hidden space-x-1 lg:flex">
                    {selectedValues.size > 2 ? (
                        <Badge
                            variant="secondary"
                            className="rounded-sm px-1 font-normal"
                        >
                          {selectedValues.size} selected
                        </Badge>
                    ) : (
                        options
                            .filter((option) => selectedValues.has(option.value))
                            .map((option) => (
                                <Badge
                                    variant="secondary"
                                    key={option.value}
                                    className="rounded-sm px-1 font-normal"
                                >
                                  {option.label}
                                </Badge>
                            ))
                    )}
                  </div>
                </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0" align="start">
          <Command>
            <CommandInput placeholder={`Filter ${title}`} />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues.has(option.value);
                  return (
                      <CommandItem
                          key={option.value}
                          onSelect={() => handleSelect(option.value)}
                      >
                        <div
                            className={cn(
                                'flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                isSelected
                                    ? 'bg-primary text-primary-foreground'
                                    : 'opacity-50 [&_svg]:invisible'
                            )}
                        >
                          <CheckIcon className="h-4 w-4" />
                        </div>
                        {option.icon && (
                            <option.icon className="h-4 w-4 text-muted-foreground" />
                        )}
                        <span>{option.label}</span>
                      </CommandItem>
                  );
                })}
              </CommandGroup>
              {selectedValues.size > 0 && (
                  <>
                    <CommandSeparator />
                    <CommandGroup>
                      <CommandItem
                          onSelect={handleClearFilters}
                          className="justify-center text-center"
                      >
                        Clear filters
                      </CommandItem>
                    </CommandGroup>
                  </>
              )}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
  );
}
