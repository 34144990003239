import {cn} from "@/lib/utils";
import srnlLogo  from "@/assets/images/srnl_logo.png"

interface Props {
  children: React.ReactNode
}

export default function AuthLayout({ children }: Props) {
  return (
    <div className='container grid h-svh flex-col items-center justify-center bg-primary-foreground lg:max-w-none lg:px-0'>
      <div className='mx-auto flex w-full flex-col justify-center space-y-2 sm:w-[480px] lg:p-8'>
        <div className='mb-4 flex items-center justify-center'>
          <div className={cn("relative flex items-center justify-center")}>
            <img
                src={srnlLogo}
                alt="Logo"
                className="h-14 w-30 object-contain"
                loading="lazy" // Improves performance for large images
            />
          </div>
          {/*<h1 className='text-3xl font-extrabold'>SRNL</h1>*/}
        </div>
        {children}
      </div>
    </div>
  )
}
