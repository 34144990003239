// @ts-nocheck

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {useAccountManager} from "@/hooks/use-account-hook";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export function UserNav() {

  const navigate = useNavigate();

  const {data: profileData} = useAccountManager().useFetchProfileByID(Number(localStorage.getItem("id")));

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");


  useEffect(() => {
    if (profileData && profileData.data) {
      setEmail(profileData.data.email);
      setFirstName(profileData.data.firstName);
      setLastName(profileData.data.lastName);
    }

  }, [profileData]);
  {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="relative h-8 w-8 rounded-full">
            <Avatar className="h-8 w-8">
              <AvatarImage
                src={"N/A"}
                alt={"N/A"}
              />
              <AvatarFallback>{String(firstName[0]+lastName[0])}</AvatarFallback>
            </Avatar>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {firstName}
              </p>
              <p className="text-xs leading-none text-muted-foreground">
                {email}
              </p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {/*<DropdownMenuGroup>*/}
          {/*  <DropdownMenuItem>*/}
          {/*    <Link to={"/dashboard/profile/update"}>Profile</Link>*/}
          {/*  </DropdownMenuItem>*/}
          {/*  <DropdownMenuItem>*/}
          {/*    Settings*/}
          {/*  </DropdownMenuItem>*/}
          {/*</DropdownMenuGroup>*/}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              navigate("/sign-out");
            }}
          >
            Sign Out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
}
