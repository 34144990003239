import { Cross2Icon, ActivityLogIcon } from '@radix-ui/react-icons';
import React, { useState, forwardRef } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Combobox } from '@/components/combo-box';
import { IconPlus } from "@tabler/icons-react";

interface GlassDatabaseFiltersProps {
    databaseOxides: { database_id: number; oxide_name: string, min_value?: number; max_value?: number  }[];
    databaseProperties: { database_id: number; property_name: string, min_value?: number; max_value?: number }[];
    setFilters: (filters: any) => void;
    filters: any;
    propertyKey: string;
    compositionKey: string;
    setPropertyKey: (key: string) => void;
    setCompositionKey: (key: string) => void;
    oxideFilterRef: React.Ref<HTMLDivElement>; // Ref Type Updated
}

export const GlassDatabaseFilters = forwardRef<HTMLDivElement, GlassDatabaseFiltersProps>(
    ({
         databaseOxides,
         databaseProperties,
         filters,
         setFilters,
         propertyKey,
         compositionKey,
         setPropertyKey,
         setCompositionKey,
         oxideFilterRef,
     }, ref) => {
        const [compositionOperation, setCompositionOperation] = useState('RANGE (Inclusive)');
        const [propertyOperation, setPropertyOperation] = useState('RANGE (Inclusive)');
        const [compositionRange, setCompositionRange] = useState({ min: '', max: '' });
        const [propertyRange, setPropertyRange] = useState({ min: '', max: '' });
        const [compositionValue, setCompositionValue] = useState('');
        const [propertyValue, setPropertyValue] = useState('');
        const [invalidFields, setInvalidFields] = useState({ composition: false, property: false });

        const isValidNumber = (value: string): boolean => {
            return /^-?\d*(\.\d+)?$/.test(value.trim());
        };

        const isDuplicateFilter = (type: 'compositions' | 'properties', newFilter: any) => {
            return filters[type].some((filter: any) =>
                JSON.stringify(filter) === JSON.stringify(newFilter)
            );
        };

        const addFilter = (type: 'compositions' | 'properties', newFilter: any) => {
            if (!isDuplicateFilter(type, newFilter)) {
                setFilters((prev) => ({
                    ...prev,
                    [type]: [...prev[type], newFilter],
                }));
            }
        };

        const removeFilter = (type: 'compositions' | 'properties', index: number) => {
            setFilters((prev) => ({
                ...prev,
                [type]: prev[type].filter((_, i) => i !== index),
            }));
        };

        const resetFilters = () => {
            setFilters({ compositions: [], properties: [] });
            setCompositionRange({ min: '', max: '' });
            setPropertyRange({ min: '', max: '' });
            setCompositionKey('');
            setCompositionValue('');
            setPropertyKey('');
            setPropertyValue('');
            setInvalidFields({ composition: false, property: false });
        };

        const handleAddCompositionFilter = () => {
            const isInvalidRange =
                compositionOperation.includes('RANGE') &&
                (Number(compositionRange.min) > Number(compositionRange.max) ||
                    !isValidNumber(compositionRange.min) ||
                    !isValidNumber(compositionRange.max));

            if (isInvalidRange) {
                setInvalidFields((prev) => ({ ...prev, composition: true }));
                return;
            }

            const filter = compositionOperation.includes('RANGE')
                ? {
                    key: compositionKey,
                    operation: 'RANGE',
                    range: {
                        min: compositionRange.min,
                        max: compositionRange.max,
                        inclusive: compositionOperation.includes('Inclusive'),
                    },
                }
                : {
                    key: compositionKey,
                    operation: compositionOperation,
                    value: compositionValue,
                };

            addFilter('compositions', filter);
            setCompositionKey('');
            setCompositionValue('');
            setCompositionRange({ min: '', max: '' });
            setInvalidFields((prev) => ({ ...prev, composition: false }));
        };

        const handleAddPropertyFilter = () => {
            const isInvalidRange =
                propertyOperation.includes('RANGE') &&
                (Number(propertyRange.min) > Number(propertyRange.max) ||
                    !isValidNumber(propertyRange.min) ||
                    !isValidNumber(propertyRange.max));

            if (isInvalidRange) {
                setInvalidFields((prev) => ({ ...prev, property: true }));
                return;
            }

            const filter = propertyOperation.includes('RANGE')
                ? {
                    key: propertyKey,
                    operation: 'RANGE',
                    range: {
                        min: propertyRange.min,
                        max: propertyRange.max,
                        inclusive: propertyOperation.includes('Inclusive'),
                    },
                }
                : {
                    key: propertyKey,
                    operation: propertyOperation,
                    value: propertyValue,
                };

            addFilter('properties', filter);
            setPropertyKey('');
            setPropertyValue('');
            setPropertyRange({ min: '', max: '' });
            setInvalidFields((prev) => ({ ...prev, property: false }));
        };

        return (
            <Card ref={ref} className="shadow-sm mt-4">
                <div className="flex flex-row justify-between items-center pr-2">
                    <CardHeader>
                        <CardTitle className="text-lg font-bold flex gap-2 items-center justify-center">
                            {/*<ActivityLogIcon className="h-5 w-5 font-extrabold"/>*/}
                            <p ref={oxideFilterRef}>Available Filters</p>
                        </CardTitle>
                    </CardHeader>
                </div>
                <CardContent className="flex flex-wrap justify-between gap-2 w-full">
                    {/* Composition Filters */}
                    <Card className="shadow-sm flex flex-row items-center justify-center gap-2 p-2 flex-wrap">
                        <Combobox
                            frameworks={databaseOxides.map((oxide) => ({
                                label: oxide.oxide_name,
                                value: oxide.oxide_name,
                                min: oxide.min_value,
                                max: oxide.max_value,
                            }))}
                            value={compositionKey}
                            setValue={setCompositionKey}
                            placeholder="Filter by oxides"
                        />
                        <select
                            value={compositionOperation}
                            onChange={(e) => setCompositionOperation(e.target.value)}
                            className="h-8 border rounded-md text-xs font-light text-center"
                        >
                            <option value="=">Equals</option>
                            <option value="!=">Not Equals</option>
                            <option value=">">Greater Than</option>
                            <option value=">=">Greater Than or Equal</option>
                            <option value="<">Less Than</option>
                            <option value="<=">Less Than or Equal</option>
                            <option value="RANGE (Inclusive)">Range (Inclusive)</option>
                            <option value="RANGE (Exclusive)">Range (Exclusive)</option>
                        </select>
                        {compositionOperation.includes('RANGE') ? (
                            <div className="flex gap-x-2 items-center">
                                <Input
                                    placeholder="Min"
                                    value={compositionRange.min}
                                    onChange={(e) => {
                                        if (isValidNumber(e.target.value)) {
                                            setCompositionRange((prev) => ({
                                                ...prev,
                                                min: e.target.value,
                                            }));
                                        }
                                    }}
                                    className={`h-8 w-20 ${
                                        invalidFields.composition ? 'invalid-input' : ''
                                    }`}
                                />
                                <Input
                                    placeholder="Max"
                                    value={compositionRange.max}
                                    onChange={(e) => {
                                        if (isValidNumber(e.target.value)) {
                                            setCompositionRange((prev) => ({
                                                ...prev,
                                                max: e.target.value,
                                            }));
                                        }
                                    }}
                                    className={`h-8 w-20 ${
                                        invalidFields.composition ? 'invalid-input' : ''
                                    }`}
                                />
                            </div>
                        ) : (
                            <Input
                                placeholder="Value"
                                value={compositionValue}
                                onChange={(e) => {
                                    if (isValidNumber(e.target.value)) {
                                        setCompositionValue(e.target.value);
                                    }
                                }}
                                className="h-8 w-40"
                            />
                        )}
                        <Button
                            variant="default"
                            size="sm"
                            onClick={handleAddCompositionFilter}
                            disabled={
                                compositionKey === '' ||
                                (compositionOperation.includes('RANGE')
                                    ? compositionRange.min === '' || compositionRange.max === ''
                                    : compositionValue === '')
                            }
                        >
                            Apply
                        </Button>
                    </Card>

                    {/* Property Filters */}
                    <Card className="shadow-sm flex flex-row items-center justify-center gap-2 p-2 flex-wrap">
                        <Combobox
                            frameworks={databaseProperties.map((property) => ({
                                label: property.property_name,
                                value: property.property_name,
                                min: property.min_value,
                                max: property.max_value,
                            }))}
                            value={propertyKey}
                            setValue={setPropertyKey}
                            placeholder="Filter by properties"
                        />
                        <select
                            value={propertyOperation}
                            onChange={(e) => setPropertyOperation(e.target.value)}
                            className="h-8 border rounded-md text-xs font-light text-center"
                        >
                            <option value="=">Equals</option>
                            <option value="!=">Not Equals</option>
                            <option value=">">Greater Than</option>
                            <option value=">=">Greater Than or Equal</option>
                            <option value="<">Less Than</option>
                            <option value="<=">Less Than or Equal</option>
                            <option value="RANGE (Inclusive)">Range (Inclusive)</option>
                            <option value="RANGE (Exclusive)">Range (Exclusive)</option>
                        </select>
                        {propertyOperation.includes('RANGE') ? (
                            <div className="flex gap-x-2 items-center">
                                <Input
                                    placeholder="Min"
                                    value={propertyRange.min}
                                    onChange={(e) => {
                                        if (isValidNumber(e.target.value)) {
                                            setPropertyRange((prev) => ({
                                                ...prev,
                                                min: e.target.value,
                                            }));
                                        }
                                    }}
                                    className={`h-8 w-20 ${
                                        invalidFields.property ? 'invalid-input' : ''
                                    }`}
                                />
                                <Input
                                    placeholder="Max"
                                    value={propertyRange.max}
                                    onChange={(e) => {
                                        if (isValidNumber(e.target.value)) {
                                            setPropertyRange((prev) => ({
                                                ...prev,
                                                max: e.target.value,
                                            }));
                                        }
                                    }}
                                    className={`h-8 w-20 ${
                                        invalidFields.property ? 'invalid-input' : ''
                                    }`}
                                />
                            </div>
                        ) : (
                            <Input
                                placeholder="Value"
                                value={propertyValue}
                                onChange={(e) => {
                                    if (isValidNumber(e.target.value)) {
                                        setPropertyValue(e.target.value);
                                    }
                                }}
                                className="h-8 w-40"
                            />
                        )}
                        <Button
                            variant="default"
                            size="sm"
                            onClick={handleAddPropertyFilter}
                            disabled={
                                propertyKey === '' ||
                                (propertyOperation.includes('RANGE')
                                    ? propertyRange.min === '' || propertyRange.max === ''
                                    : propertyValue === '')
                            }
                        >
                            Apply
                        </Button>
                    </Card>
                </CardContent>
                <CardContent className="flex flex-wrap justify-between gap-2 w-full">
                    <div className="flex flex-row items-center flex-wrap">
                        <p className="text-sm font-semibold gap-2 p-2">Filters Applied:</p>
                        <div className="flex flex-wrap gap-2 items-center">
                            {filters.compositions.map((filter, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 border rounded-lg px-3 py-1 bg-gray-100 m-2 font-light text-xs"
                                >
                                    <span>
                                        <b>{filter.key}</b> {filter.operation} {filter.operation === 'RANGE'
                                        ? `${filter.range?.min} - ${filter.range?.max} (${
                                            filter.range?.inclusive ? 'Inclusive' : 'Exclusive'
                                        })`
                                        : filter.value}
                                    </span>
                                    <button onClick={() => removeFilter('compositions', index)}>
                                        <Cross2Icon className="h-4 w-4"/>
                                    </button>
                                </div>
                            ))}
                            {filters.properties.map((filter, index) => (
                                <div
                                    key={index}
                                    className="flex items-center gap-2 border rounded-lg px-3 py-1 bg-gray-100 m-2 font-light text-xs"
                                >
                                    <span>
                                        <b>{filter.key}</b> {filter.operation} {filter.operation === 'RANGE'
                                        ? `${filter.range?.min} - ${filter.range?.max} (${
                                            filter.range?.inclusive ? 'Inclusive' : 'Exclusive'
                                        })`
                                        : filter.value}
                                    </span>
                                    <button onClick={() => removeFilter('properties', index)}>
                                        <Cross2Icon className="h-4 w-4"/>
                                    </button>
                                </div>
                            ))}
                        </div>
                        <Button
                            variant="outline"
                            onClick={resetFilters}
                            className="h-6 px-2 lg:px-3"
                        >
                            Reset
                            <Cross2Icon className="ml-2 h-4 w-4"/>
                        </Button>
                    </div>
                </CardContent>
            </Card>
        );
    }
);

GlassDatabaseFilters.displayName = 'GlassDatabaseFilters';
