import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons'
import { Table } from '@tanstack/react-table'
import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  onPageChange: (pageIndex: number) => void;
  onLimitChange: (pageSize: number) => void;
    totalRows: number;
    pageSize: number
}

export function DataTablePagination<TData>({
                                             table,
                                             onPageChange,
                                             onLimitChange,
                                               totalRows,
                                               pageSize,
                                           }: DataTablePaginationProps<TData>) {
  const pageIndex = table.getState().pagination.pageIndex;

  return (
      <div className="flex items-center justify-between overflow-auto px-2">
        <div className="hidden flex-1 text-sm text-muted-foreground sm:block">
          {/*{table.getFilteredSelectedRowModel().rows.length} of{' '}*/}
          {/*{table.getFilteredRowModel().rows.length} row(s) selected.*/}
            Total of {totalRows} row(s) available.
        </div>
        <div className="flex items-center sm:space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="hidden text-sm font-medium sm:block">Rows per page</p>
            <Select
                value={`${pageSize}`}
                onValueChange={(value) => {
                  const newPageSize = Number(value);
                  table.setPageSize(newPageSize);
                  onLimitChange(newPageSize); // Notify parent of the change
                }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {[10, 20, 30, 40, 50].map((size) => (
                    <SelectItem key={size} value={`${size}`}>
                      {size}
                    </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {pageIndex + 1} of {table.getPageCount()}
          </div>
          <div className="flex items-center space-x-2">
            <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => {
                  table.setPageIndex(0);
                  onPageChange(0); // Notify parent of the change
                }}
                disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
            <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => {
                  const newPageIndex = pageIndex - 1;
                  table.previousPage();
                  onPageChange(newPageIndex); // Notify parent of the change
                }}
                disabled={!table.getCanPreviousPage()}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
                variant="outline"
                className="h-8 w-8 p-0"
                onClick={() => {
                  const newPageIndex = pageIndex + 1;
                  table.nextPage();
                  onPageChange(newPageIndex); // Notify parent of the change
                }}
                disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
                variant="outline"
                className="hidden h-8 w-8 p-0 lg:flex"
                onClick={() => {
                  const lastPageIndex = table.getPageCount() - 1;
                  table.setPageIndex(lastPageIndex);
                  onPageChange(lastPageIndex); // Notify parent of the change
                }}
                disabled={!table.getCanNextPage()}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
  );
}
