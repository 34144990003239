import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Alert } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Typography } from "@mui/material";
import { toast } from "@/hooks/use-toast";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import {Card} from "@/components/ui/card";
import {usePublicManager} from "@/hooks/use-public-hook";

// Zod schema for form validation
const contactSchema = z.object({
    name: z.string().min(1, "Name is required"),
    email: z.string().email("Enter a valid email"),
    phone: z.string().optional(),
    message: z.string().min(1, "Message is required"),
});

// Infer the TypeScript type from the schema
type ContactFormInputs = z.infer<typeof contactSchema>;

const ContactUsForm: React.FC = () => {
    const form = useForm<ContactFormInputs>({
        resolver: zodResolver(contactSchema),
        defaultValues: {
            name: "",
            email: "",
            phone: "",
            message: "",
        },
    });

    const {mutate: mutateContactUs} = usePublicManager().useMutateContactUs()

    const onSubmit = async (formData: ContactFormInputs) => {

        const onSuccess = () => {
            toast({
                title: "Sent",
                description: "Thank you for your message! We will be in touch soon.",
            });
            form.reset();
        }

        const onError = () => {
            toast({
                variant: "destructive",
                title: "Failed",
                description: "There was an issue submitting your message. Please try again.",
            });
        }

        mutateContactUs({firstName: formData.name, lastName: "", email: formData.email, phoneNumber: formData.phone, message: formData.message}, {onSuccess, onError})

    };

    return (
        <Card className="shadow-md p-10 flex items-center justify-center flex-wrap">
            <div className="flex flex-row gap-2 ">
                {/* Contact Information */}
                <div className="w-full p-2">
                    <Typography variant="h6" className="font-semibold">
                        Got a Query? Reach Out to Our Team
                    </Typography>
                    {/*<Typography className="mt-2">*/}
                    {/*    We're here to help! Contact us using the form or get in touch with*/}
                    {/*    one of our team members directly.*/}
                    {/*</Typography>*/}
                    <div className="mt-6 space-y-4">
                        {[
                            {
                                name: "Cory Trivelpiece",
                                role: "SRNL",
                                email: "Cory.Trivelpiece@srnl.doe.gov",
                            },
                            {
                                name: "Xiaonan Lu",
                                role: "PNNL",
                                email: "xiaonan.lu@pnnl.gov",
                            },
                            {
                                name: "Collin Wilkinson",
                                role: "Alfred University",
                                email: "wilkinsonc@alfred.edu",
                            },
                            {
                                name: "Elizabeth Tsekrekas",
                                role: "SRNL",
                                email: "elizabeth.tsekrekas@srnl.doe.gov",
                            },
                            {
                                name: "Dilpuneet Aidhy",
                                role: "Clemson University",
                                email: "daidhy@clemson.edu",
                            },
                            {
                                name: "Mohit Singh",
                                role: "Clemson University",
                                email: "msingh3@clemson.edu",
                            },
                        ].map((contact, idx) => (
                            <div key={idx}>
                                <p className="">
                                    {contact.name} | <span className="text-blue-900">{contact.role}</span>
                                </p>
                                <p className="font-light text-orange-700">{contact.email}</p>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Contact Form */}
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4 w-full p-2">
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Your Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Your Email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="phone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Phone (Optional)</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Your Phone" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="message"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Message</FormLabel>
                                    <FormControl>
                                        <Textarea placeholder="Your Message" rows={4} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button
                            type="submit"
                            disabled={form.formState.isSubmitting}
                        >
                            {form.formState.isSubmitting ? "Sending..." : "Send"}
                        </Button>
                    </form>
                </Form>
            </div>
        </Card>
    );
};

export default ContactUsForm;
