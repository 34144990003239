// @ts-nocheck

import React, { HTMLAttributes, useState } from 'react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from '@/components/ui/dialog'
import { useAuthManager } from "@/hooks/use-auth-hook"
import { useNavigate } from "react-router-dom"

export function EmailVerificationForm({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  const [open, setOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle')

  const { useEmailVerify } = useAuthManager()
  const { mutate: emailVerify, isLoading } = useEmailVerify()

  const navigate = useNavigate()

  const verifyEmail = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search)
      const token = urlParams.get("token") || ""

      const onSuccess = (response: any) => {
        if (response.code === 200) {
          setStatus("success")
          setOpen(true)
          setTimeout(() => navigate("/sign-in"), 10000) // Redirect after 5 seconds
        }
      }

      const onError = () => {
        setStatus("error")
        setOpen(true)
      }

      emailVerify({ token }, { onSuccess, onError })
    } catch (error) {
      setStatus("error")
      setOpen(true)
    }
  }

  return (
      <div className={cn('grid gap-6', className)} {...props}>
        <Button onClick={verifyEmail} disabled={isLoading}>
          {isLoading ? 'Verifying...' : 'Verify Email'}
        </Button>

        {status === 'success' && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Your account is almost ready!</DialogTitle>
                </DialogHeader>
                <p>Your email has been verified, and your account is now <span
                    className="highlight">pending approval</span> from our team. We will notify you as soon as your
                  account has been approved and is ready for use.  Redirecting to sign-in...</p>
              </DialogContent>
            </Dialog>
        )}

        {status === 'error' && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Verification Failed</DialogTitle>
                </DialogHeader>
                <p>The verification link has expired or is invalid. Please request a new link on the sign-in page. For any assistance, you can reach out to us at {' '}
                  <a
                      href="mailto:mcdcadmin@clemson.edu"
                      className="underline font-bold text-blue-300"
                  >
                    mcdcadmin@clemson.edu
                  </a>.</p>
              </DialogContent>
            </Dialog>
        )}
      </div>
  )
}
