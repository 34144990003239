import srnlicon from './images/srnl_icon.png';
import srnllogo from './images/srnl_logo.png';
import alfredlogo from './images/alfred_university_logo.png';
import clemsonlogo from './images/clemson_logo.png';
import doeemlogo from './images/doe_em_logo.png';
import northwestlogo from './images/pacific_northwest_logo.png';

const images = {
    srnlicon,
    srnllogo,
    alfredlogo,
    clemsonlogo,
    doeemlogo,
    northwestlogo,
};

export default images;