interface IElement {
    Element: string;
    Symbol: string;
    "Atomic-Number": number;
    Weight: number;
    Group: number | string;
    Period: number;
    Family: string;
    Category?: string
}

export const period1Elements1: IElement[] = [
    {
        Element: "Hydrogen",
        Symbol: "H",
        "Atomic-Number": 1,
        Weight: 1.008,
        Group: 1,
        Period: 1,
        Family: "Hydrogen",
    },
    {
        Element: "Helium",
        Symbol: "He",
        "Atomic-Number": 2,
        Weight: 4.0026,
        Group: 18,
        Period: 1,
        Family: "Noble Gases",
    },
];

export const period2LeftMostElements1: IElement[] = [
    {
        Element: "Lithium",
        Symbol: "Li",
        "Atomic-Number": 3,
        Weight: 6.941,
        Group: 1,
        Period: 2,
        Family: "Alkali Metals",
    },
    {
        Element: "Beryllium",
        Symbol: "Be",
        "Atomic-Number": 4,
        Weight: 9.0122,
        Group: 2,
        Period: 2,
        Family: "Alkali Earth Metals",
    },
];

export const period2RightMostElements1: IElement[] = [
    {
        Element: "Boron",
        Symbol: "B",
        "Atomic-Number": 5,
        Weight: 10.81,
        Group: 13,
        Period: 2,
        Family: "Boron Family",
    },
    {
        Element: "Carbon",
        Symbol: "C",
        "Atomic-Number": 6,
        Weight: 12.011,
        Group: 14,
        Period: 2,
        Family: "Carbon Family",
    },
    {
        Element: "Nitrogen",
        Symbol: "N",
        "Atomic-Number": 7,
        Weight: 14.007,
        Group: 15,
        Period: 2,
        Family: "Nitrogen Family",
    },
    {
        Element: "Oxygen",
        Symbol: "O",
        "Atomic-Number": 8,
        Weight: 15.999,
        Group: 16,
        Period: 2,
        Family: "Chalcogens Family",
    },
    {
        Element: "Fluorine",
        Symbol: "F",
        "Atomic-Number": 9,
        Weight: 18.998,
        Group: 17,
        Period: 2,
        Family: "Halogens Family",
    },
    {
        Element: "Neon",
        Symbol: "Ne",
        "Atomic-Number": 10,
        Weight: 20.18,
        Group: 18,
        Period: 2,
        Family: "Noble Gases",
    },
];

export const period3LeftMostElements1: IElement[] = [
    {
        Element: "Sodium",
        Symbol: "Na",
        "Atomic-Number": 11,
        Weight: 22.99,
        Group: 1,
        Period: 3,
        Family: "Alkali Metals",
    },
    {
        Element: "Magnesium",
        Symbol: "Mg",
        "Atomic-Number": 12,
        Weight: 24.305,
        Group: 2,
        Period: 3,
        Family: "Alkali Earth Metals",
    },
];

export const period3RightMostElements1: IElement[] = [
    {
        Element: "Aluminium",
        Symbol: "Al",
        "Atomic-Number": 13,
        Weight: 26.982,
        Group: 13,
        Period: 3,
        Family: "Boron Family",
    },
    {
        Element: "Silicon",
        Symbol: "Si",
        "Atomic-Number": 14,
        Weight: 28.085,
        Group: 14,
        Period: 3,
        Family: "Carbon Family",
    },
    {
        Element: "Phosphorus",
        Symbol: "P",
        "Atomic-Number": 15,
        Weight: 30.974,
        Group: 15,
        Period: 3,
        Family: "Nitrogen Family",
    },
    {
        Element: "Sulfur",
        Symbol: "S",
        "Atomic-Number": 16,
        Weight: 32.06,
        Group: 16,
        Period: 3,
        Family: "Chalcogens Family",
    },
    {
        Element: "Chlorine",
        Symbol: "Cl",
        "Atomic-Number": 17,
        Weight: 35.45,
        Group: 17,
        Period: 3,
        Family: "Halogens Family",
    },
    {
        Element: "Argon",
        Symbol: "Ar",
        "Atomic-Number": 18,
        Weight: 39.948,
        Group: 18,
        Period: 3,
        Family: "Noble Gases",
    },
];

export const lanthanidesElements1: IElement[] = [
    {
        Element: "Lanthanum",
        Symbol: "La",
        "Atomic-Number": 57,
        Weight: 138.91,
        Group: 4,
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Cerium",
        Symbol: "Ce",
        "Atomic-Number": 58,
        Weight: 140.12,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Praseodymium",
        Symbol: "Pr",
        "Atomic-Number": 59,
        Weight: 140.91,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Neodymium",
        Symbol: "Nd",
        "Atomic-Number": 60,
        Weight: 144.24,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Promethium",
        Symbol: "Pm",
        "Atomic-Number": 61,
        Weight: 145,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Samarium",
        Symbol: "Sm",
        "Atomic-Number": 62,
        Weight: 150.36,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Europium",
        Symbol: "Eu",
        "Atomic-Number": 63,
        Weight: 151.96,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Gadolinium",
        Symbol: "Gd",
        "Atomic-Number": 64,
        Weight: 157.25,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Terbium",
        Symbol: "Tb",
        "Atomic-Number": 65,
        Weight: 158.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Dysprosium",
        Symbol: "Dy",
        "Atomic-Number": 66,
        Weight: 162.5,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Holmium",
        Symbol: "Ho",
        "Atomic-Number": 67,
        Weight: 164.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Erbium",
        Symbol: "Er",
        "Atomic-Number": 68,
        Weight: 167.26,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Thulium",
        Symbol: "Tm",
        "Atomic-Number": 69,
        Weight: 168.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Ytterbium",
        Symbol: "Yb",
        "Atomic-Number": 70,
        Weight: 173.05,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
];

export const actinidesElements1: IElement[] = [
    {
        Element: "Actinium",
        Symbol: "Ac",
        "Atomic-Number": 89,
        Weight: 227,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Thorium",
        Symbol: "Th",
        "Atomic-Number": 90,
        Weight: 232.04,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Protactinium",
        Symbol: "Pa",
        "Atomic-Number": 91,
        Weight: 231.04,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Uranium",
        Symbol: "U",
        "Atomic-Number": 92,
        Weight: 238.03,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Neptunium",
        Symbol: "Np",
        "Atomic-Number": 93,
        Weight: 237,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Plutonium",
        Symbol: "Pu",
        "Atomic-Number": 94,
        Weight: 244,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Americium",
        Symbol: "Am",
        "Atomic-Number": 95,
        Weight: 243,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Curium",
        Symbol: "Cm",
        "Atomic-Number": 96,
        Weight: 247,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Berkelium",
        Symbol: "Bk",
        "Atomic-Number": 97,
        Weight: 247,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Californium",
        Symbol: "Cf",
        "Atomic-Number": 98,
        Weight: 251,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Einsteinium",
        Symbol: "Es",
        "Atomic-Number": 99,
        Weight: 252,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Fermium",
        Symbol: "Fm",
        "Atomic-Number": 100,
        Weight: 257,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Mendelevium",
        Symbol: "Md",
        "Atomic-Number": 101,
        Weight: 258,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Nobelium",
        Symbol: "No",
        "Atomic-Number": 102,
        Weight: 259,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
];
export const elements: IElement[] = [
    {
        Element: "Hydrogen",
        Symbol: "H",
        "Atomic-Number": 1,
        Weight: 1.008,
        Group: 1,
        Period: 1,
        Family: "Hydrogen",
    },
    {
        Element: "Helium",
        Symbol: "He",
        "Atomic-Number": 2,
        Weight: 4.0026,
        Group: 18,
        Period: 1,
        Family: "Noble Gases",
    },
    {
        Element: "Lithium",
        Symbol: "Li",
        "Atomic-Number": 3,
        Weight: 6.941,
        Group: 1,
        Period: 2,
        Family: "Alkali Metals",
    },
    {
        Element: "Beryllium",
        Symbol: "Be",
        "Atomic-Number": 4,
        Weight: 9.0122,
        Group: 2,
        Period: 2,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Boron",
        Symbol: "B",
        "Atomic-Number": 5,
        Weight: 10.81,
        Group: 13,
        Period: 2,
        Family: "Boron Family",
    },
    {
        Element: "Carbon",
        Symbol: "C",
        "Atomic-Number": 6,
        Weight: 12.011,
        Group: 14,
        Period: 2,
        Family: "Carbon Family",
    },
    {
        Element: "Nitrogen",
        Symbol: "N",
        "Atomic-Number": 7,
        Weight: 14.007,
        Group: 15,
        Period: 2,
        Family: "Nitrogen Family",
    },
    {
        Element: "Oxygen",
        Symbol: "O",
        "Atomic-Number": 8,
        Weight: 15.999,
        Group: 16,
        Period: 2,
        Family: "Chalcogens Family",
    },
    {
        Element: "Fluorine",
        Symbol: "F",
        "Atomic-Number": 9,
        Weight: 18.998,
        Group: 17,
        Period: 2,
        Family: "Halogens Family",
    },
    {
        Element: "Neon",
        Symbol: "Ne",
        "Atomic-Number": 10,
        Weight: 20.18,
        Group: 18,
        Period: 2,
        Family: "Noble Gases",
    },
    {
        Element: "Sodium",
        Symbol: "Na",
        "Atomic-Number": 11,
        Weight: 22.99,
        Group: 1,
        Period: 3,
        Family: "Alkali Metals",
    },
    {
        Element: "Magnesium",
        Symbol: "Mg",
        "Atomic-Number": 12,
        Weight: 24.305,
        Group: 2,
        Period: 3,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Aluminium",
        Symbol: "Al",
        "Atomic-Number": 13,
        Weight: 26.982,
        Group: 13,
        Period: 3,
        Family: "Boron Family",
    },
    {
        Element: "Silicon",
        Symbol: "Si",
        "Atomic-Number": 14,
        Weight: 28.085,
        Group: 14,
        Period: 3,
        Family: "Carbon Family",
    },
    {
        Element: "Phosphorus",
        Symbol: "P",
        "Atomic-Number": 15,
        Weight: 30.974,
        Group: 15,
        Period: 3,
        Family: "Nitrogen Family",
    },
    {
        Element: "Sulfur",
        Symbol: "S",
        "Atomic-Number": 16,
        Weight: 32.06,
        Group: 16,
        Period: 3,
        Family: "Chalcogens Family",
    },
    {
        Element: "Chlorine",
        Symbol: "Cl",
        "Atomic-Number": 17,
        Weight: 35.45,
        Group: 17,
        Period: 3,
        Family: "Halogens Family",
    },
    {
        Element: "Argon",
        Symbol: "Ar",
        "Atomic-Number": 18,
        Weight: 39.948,
        Group: 18,
        Period: 3,
        Family: "Noble Gases",
    },
    {
        Element: "Potassium",
        Symbol: "K",
        "Atomic-Number": 19,
        Weight: 39.098,
        Group: 1,
        Period: 4,
        Family: "Alkali Metals",
    },
    {
        Element: "Calcium",
        Symbol: "Ca",
        "Atomic-Number": 20,
        Weight: 40.078,
        Group: 2,
        Period: 4,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Scandium",
        Symbol: "Sc",
        "Atomic-Number": 21,
        Weight: 44.956,
        Group: 3,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Titanium",
        Symbol: "Ti",
        "Atomic-Number": 22,
        Weight: 47.867,
        Group: 4,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Vanadium",
        Symbol: "V",
        "Atomic-Number": 23,
        Weight: 50.942,
        Group: 5,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Chromium",
        Symbol: "Cr",
        "Atomic-Number": 24,
        Weight: 51.996,
        Group: 6,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Manganese",
        Symbol: "Mn",
        "Atomic-Number": 25,
        Weight: 54.938,
        Group: 7,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Iron",
        Symbol: "Fe",
        "Atomic-Number": 26,
        Weight: 55.845,
        Group: 8,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Cobalt",
        Symbol: "Co",
        "Atomic-Number": 27,
        Weight: 58.933,
        Group: 9,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Nickel",
        Symbol: "Ni",
        "Atomic-Number": 28,
        Weight: 58.693,
        Group: 10,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Copper",
        Symbol: "Cu",
        "Atomic-Number": 29,
        Weight: 63.546,
        Group: 11,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Zinc",
        Symbol: "Zn",
        "Atomic-Number": 30,
        Weight: 65.38,
        Group: 12,
        Period: 4,
        Family: "Transitions Metals",
    },
    {
        Element: "Gallium",
        Symbol: "Ga",
        "Atomic-Number": 31,
        Weight: 69.723,
        Group: 13,
        Period: 4,
        Family: "Boron Family",
    },
    {
        Element: "Germanium",
        Symbol: "Ge",
        "Atomic-Number": 32,
        Weight: 72.63,
        Group: 14,
        Period: 4,
        Family: "Carbon Family",
    },
    {
        Element: "Arsenic",
        Symbol: "As",
        "Atomic-Number": 33,
        Weight: 74.922,
        Group: 15,
        Period: 4,
        Family: "Nitrogen Family",
    },
    {
        Element: "Selenium",
        Symbol: "Se",
        "Atomic-Number": 34,
        Weight: 78.971,
        Group: 16,
        Period: 4,
        Family: "Chalcogens Family",
    },
    {
        Element: "Bromine",
        Symbol: "Br",
        "Atomic-Number": 35,
        Weight: 79.904,
        Group: 17,
        Period: 4,
        Family: "Halogens Family",
    },
    {
        Element: "Krypton",
        Symbol: "Kr",
        "Atomic-Number": 36,
        Weight: 83.798,
        Group: 18,
        Period: 4,
        Family: "Noble Gases",
    },
    {
        Element: "Rubidium",
        Symbol: "Rb",
        "Atomic-Number": 37,
        Weight: 85.468,
        Group: 1,
        Period: 5,
        Family: "Alkali Metals",
    },
    {
        Element: "Strontium",
        Symbol: "Sr",
        "Atomic-Number": 38,
        Weight: 85.468,
        Group: 2,
        Period: 5,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Yttrium",
        Symbol: "Y",
        "Atomic-Number": 39,
        Weight: 85.468,
        Group: 3,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Zirconium",
        Symbol: "Zr",
        "Atomic-Number": 40,
        Weight: 85.468,
        Group: 4,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Niobium",
        Symbol: "Nb",
        "Atomic-Number": 41,
        Weight: 85.468,
        Group: 5,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Molybdenum",
        Symbol: "Mo",
        "Atomic-Number": 42,
        Weight: 95.95,
        Group: 6,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Technetium",
        Symbol: "Tc",
        "Atomic-Number": 43,
        Weight: 98,
        Group: 7,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Ruthenium",
        Symbol: "Ru",
        "Atomic-Number": 44,
        Weight: 101.07,
        Group: 8,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Rhodium",
        Symbol: "Rh",
        "Atomic-Number": 45,
        Weight: 102.91,
        Group: 9,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Palladium",
        Symbol: "Pd",
        "Atomic-Number": 46,
        Weight: 106.42,
        Group: 10,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Silver",
        Symbol: "Ag",
        "Atomic-Number": 47,
        Weight: 107.87,
        Group: 11,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Cadmium",
        Symbol: "Cd",
        "Atomic-Number": 48,
        Weight: 112.41,
        Group: 12,
        Period: 5,
        Family: "Transitions Metals",
    },
    {
        Element: "Indium",
        Symbol: "In",
        "Atomic-Number": 49,
        Weight: 114.82,
        Group: 13,
        Period: 5,
        Family: "Boron Family",
    },
    {
        Element: "Tin",
        Symbol: "Sn",
        "Atomic-Number": 50,
        Weight: 118.71,
        Group: 14,
        Period: 5,
        Family: "Carbon Family",
    },
    {
        Element: "Antimony",
        Symbol: "Sb",
        "Atomic-Number": 51,
        Weight: 121.76,
        Group: 15,
        Period: 5,
        Family: "Nitrogen Family",
    },
    {
        Element: "Tellurium",
        Symbol: "Te",
        "Atomic-Number": 52,
        Weight: 127.6,
        Group: 16,
        Period: 5,
        Family: "Chalcogens Family",
    },
    {
        Element: "Iodine",
        Symbol: "I",
        "Atomic-Number": 53,
        Weight: 126.9,
        Group: 17,
        Period: 5,
        Family: "Halogens Family",
    },
    {
        Element: "Xenon",
        Symbol: "Xe",
        "Atomic-Number": 54,
        Weight: 131.29,
        Group: 18,
        Period: 5,
        Family: "Noble Gases",
    },
    {
        Element: "Caesium",
        Symbol: "Cs",
        "Atomic-Number": 55,
        Weight: 132.91,
        Group: 1,
        Period: 6,
        Family: "Alkali Metals",
    },
    {
        Element: "Barium",
        Symbol: "Ba",
        "Atomic-Number": 56,
        Weight: 137.33,
        Group: 2,
        Period: 6,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Lanthanum",
        Symbol: "La",
        "Atomic-Number": 57,
        Weight: 138.91,
        Group: 4,
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Cerium",
        Symbol: "Ce",
        "Atomic-Number": 58,
        Weight: 140.12,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Praseodymium",
        Symbol: "Pr",
        "Atomic-Number": 59,
        Weight: 140.91,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Neodymium",
        Symbol: "Nd",
        "Atomic-Number": 60,
        Weight: 144.24,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Promethium",
        Symbol: "Pm",
        "Atomic-Number": 61,
        Weight: 145,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Samarium",
        Symbol: "Sm",
        "Atomic-Number": 62,
        Weight: 150.36,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Europium",
        Symbol: "Eu",
        "Atomic-Number": 63,
        Weight: 151.96,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Gadolinium",
        Symbol: "Gd",
        "Atomic-Number": 64,
        Weight: 157.25,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Terbium",
        Symbol: "Tb",
        "Atomic-Number": 65,
        Weight: 158.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Dysprosium",
        Symbol: "Dy",
        "Atomic-Number": 66,
        Weight: 162.5,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Holmium",
        Symbol: "Ho",
        "Atomic-Number": 67,
        Weight: 164.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Erbium",
        Symbol: "Er",
        "Atomic-Number": 68,
        Weight: 167.26,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Thulium",
        Symbol: "Tm",
        "Atomic-Number": 69,
        Weight: 168.93,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Ytterbium",
        Symbol: "Yb",
        "Atomic-Number": 70,
        Weight: 173.05,
        Group: "4f",
        Period: 6,
        Family: "Lanthanides",
    },
    {
        Element: "Lutetium",
        Symbol: "Lu",
        "Atomic-Number": 71,
        Weight: 174.97,
        Group: 3,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Hafnium",
        Symbol: "Hf",
        "Atomic-Number": 72,
        Weight: 178.49,
        Group: 4,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Tantalum",
        Symbol: "Ta",
        "Atomic-Number": 73,
        Weight: 180.95,
        Group: 5,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Tungsten",
        Symbol: "W",
        "Atomic-Number": 74,
        Weight: 183.84,
        Group: 6,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Rhenium",
        Symbol: "Re",
        "Atomic-Number": 75,
        Weight: 186.21,
        Group: 7,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Osmium",
        Symbol: "Os",
        "Atomic-Number": 76,
        Weight: 190.23,
        Group: 8,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Iridium",
        Symbol: "Ir",
        "Atomic-Number": 77,
        Weight: 192.22,
        Group: 9,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Platinum",
        Symbol: "Pt",
        "Atomic-Number": 78,
        Weight: 195.08,
        Group: 10,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Gold",
        Symbol: "Au",
        "Atomic-Number": 79,
        Weight: 196.97,
        Group: 11,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Mercury",
        Symbol: "Hg",
        "Atomic-Number": 80,
        Weight: 200.59,
        Group: 12,
        Period: 6,
        Family: "Transitions Metals",
    },
    {
        Element: "Thallium",
        Symbol: "Tl",
        "Atomic-Number": 81,
        Weight: 204.38,
        Group: 13,
        Period: 6,
        Family: "Boron Family",
    },
    {
        Element: "Lead",
        Symbol: "Pb",
        "Atomic-Number": 82,
        Weight: 207.2,
        Group: 14,
        Period: 6,
        Family: "Carbon Family",
    },
    {
        Element: "Bismuth",
        Symbol: "Bi",
        "Atomic-Number": 83,
        Weight: 208.98,
        Group: 15,
        Period: 6,
        Family: "Nitrogen Family",
    },
    {
        Element: "Polonium",
        Symbol: "Po",
        "Atomic-Number": 84,
        Weight: 209,
        Group: 16,
        Period: 6,
        Family: "Chalcogens Family",
    },
    {
        Element: "Astatine",
        Symbol: "At",
        "Atomic-Number": 85,
        Weight: 210,
        Group: 17,
        Period: 6,
        Family: "Halogens Family",
    },
    {
        Element: "Radon",
        Symbol: "Rn",
        "Atomic-Number": 86,
        Weight: 222,
        Group: 18,
        Period: 6,
        Family: "Noble Gases",
    },
    {
        Element: "Francium",
        Symbol: "Fr",
        "Atomic-Number": 87,
        Weight: 223,
        Group: 1,
        Period: 7,
        Family: "Alkali Metals",
    },
    {
        Element: "Radium",
        Symbol: "Ra",
        "Atomic-Number": 88,
        Weight: 226,
        Group: 2,
        Period: 7,
        Family: "Alkali Earth Metals",
    },
    {
        Element: "Actinium",
        Symbol: "Ac",
        "Atomic-Number": 89,
        Weight: 227,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Thorium",
        Symbol: "Th",
        "Atomic-Number": 90,
        Weight: 232.04,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Protactinium",
        Symbol: "Pa",
        "Atomic-Number": 91,
        Weight: 231.04,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Uranium",
        Symbol: "U",
        "Atomic-Number": 92,
        Weight: 238.03,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Neptunium",
        Symbol: "Np",
        "Atomic-Number": 93,
        Weight: 237,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Plutonium",
        Symbol: "Pu",
        "Atomic-Number": 94,
        Weight: 244,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Americium",
        Symbol: "Am",
        "Atomic-Number": 95,
        Weight: 243,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Curium",
        Symbol: "Cm",
        "Atomic-Number": 96,
        Weight: 247,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Berkelium",
        Symbol: "Bk",
        "Atomic-Number": 97,
        Weight: 247,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Californium",
        Symbol: "Cf",
        "Atomic-Number": 98,
        Weight: 251,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Einsteinium",
        Symbol: "Es",
        "Atomic-Number": 99,
        Weight: 252,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Fermium",
        Symbol: "Fm",
        "Atomic-Number": 100,
        Weight: 257,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Mendelevium",
        Symbol: "Md",
        "Atomic-Number": 101,
        Weight: 258,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Nobelium",
        Symbol: "No",
        "Atomic-Number": 102,
        Weight: 259,
        Group: "5f",
        Period: 7,
        Family: "Actinides",
    },
    {
        Element: "Lawrencium",
        Symbol: "Lr",
        "Atomic-Number": 103,
        Weight: 262,
        Group: 3,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Rutherfordium",
        Symbol: "Rf",
        "Atomic-Number": 104,
        Weight: 261,
        Group: 4,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Dubnium",
        Symbol: "Db",
        "Atomic-Number": 105,
        Weight: 262,
        Group: 5,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Seaborgium",
        Symbol: "Sg",
        "Atomic-Number": 106,
        Weight: 266,
        Group: 6,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Bohrium",
        Symbol: "Bh",
        "Atomic-Number": 107,
        Weight: 264,
        Group: 7,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Hassium",
        Symbol: "Hs",
        "Atomic-Number": 108,
        Weight: 277,
        Group: 8,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Meitnerium",
        Symbol: "Mt",
        "Atomic-Number": 109,
        Weight: 268,
        Group: 9,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Darmstadtium",
        Symbol: "Ds",
        "Atomic-Number": 110,
        Weight: 281,
        Group: 10,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Roentgenium",
        Symbol: "Rg",
        "Atomic-Number": 111,
        Weight: 272,
        Group: 11,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Copernicium",
        Symbol: "Cn",
        "Atomic-Number": 112,
        Weight: 285,
        Group: 12,
        Period: 7,
        Family: "Transitions Metals",
    },
    {
        Element: "Nihonium",
        Symbol: "Nh",
        "Atomic-Number": 113,
        Weight: 286,
        Group: 13,
        Period: 7,
        Family: "Boron Family",
    },
    {
        Element: "Flerovium",
        Symbol: "Fl",
        "Atomic-Number": 114,
        Weight: 289,
        Group: 14,
        Period: 7,
        Family: "Carbon Family",
    },
    {
        Element: "Moscovium",
        Symbol: "Mc",
        "Atomic-Number": 115,
        Weight: 288,
        Group: 15,
        Period: 7,
        Family: "Nitrogen Family",
    },
    {
        Element: "Livermorium",
        Symbol: "Lv",
        "Atomic-Number": 116,
        Weight: 293,
        Group: 16,
        Period: 7,
        Family: "Chalcogens Family",
    },
    {
        Element: "Tennessine",
        Symbol: "Ts",
        "Atomic-Number": 117,
        Weight: 294,
        Group: 17,
        Period: 7,
        Family: "Halogens Family",
    },
    {
        Element: "Oganesson",
        Symbol: "Og",
        "Atomic-Number": 118,
        Weight: 294,
        Group: 18,
        Period: 7,
        Family: "Noble Gases",
    },
];

export const period1Elements: IElement[] = [
    {
        Element: "Hydrogen",
        Symbol: "H",
        "Atomic-Number": 1,
        Weight: 1.008,
        Group: 1,
        Period: 1,
        Family: "Reactive Nonmetals",
        Category: "diatomic nonmetal",
    },
    {
        Element: "Helium",
        Symbol: "He",
        "Atomic-Number": 2,
        Weight: 4.0026,
        Group: 18,
        Period: 1,
        Family: "Noble Gases",
        Category: "noble gas",
    },
];

export const period2LeftMostElements: IElement[] = [
    {
        Element: "Lithium",
        Symbol: "Li",
        "Atomic-Number": 3,
        Weight: 6.941,
        Group: 1,
        Period: 2,
        Family: "Alkali Metals",
        Category: "alkali metal",
    },
    {
        Element: "Beryllium",
        Symbol: "Be",
        "Atomic-Number": 4,
        Weight: 9.0122,
        Group: 2,
        Period: 2,
        Family: "Alkaline Earth Metals",
        Category: "alkaline earth metal",
    },
];

export const period2RightMostElements: IElement[] = [
    {
        Element: "Boron",
        Symbol: "B",
        "Atomic-Number": 5,
        Weight: 10.81,
        Group: 13,
        Period: 2,
        Family: "Metalloids",
        Category: "metalloid",
    },
    {
        Element: "Carbon",
        Symbol: "C",
        "Atomic-Number": 6,
        Weight: 12.011,
        Group: 14,
        Period: 2,
        Family: "Reactive Nonmetals",
        Category: "polyatomic nonmetal",
    },
    {
        Element: "Nitrogen",
        Symbol: "N",
        "Atomic-Number": 7,
        Weight: 14.007,
        Group: 15,
        Period: 2,
        Family: "Reactive Nonmetals",
        Category: "diatomic nonmetal",
    },
    {
        Element: "Oxygen",
        Symbol: "O",
        "Atomic-Number": 8,
        Weight: 15.999,
        Group: 16,
        Period: 2,
        Family: "Reactive Nonmetals",
        Category: "diatomic nonmetal",
    },
    {
        Element: "Fluorine",
        Symbol: "F",
        "Atomic-Number": 9,
        Weight: 18.998,
        Group: 17,
        Period: 2,
        Family: "Reactive Nonmetals",
        Category: "diatomic nonmetal",
    },
    {
        Element: "Neon",
        Symbol: "Ne",
        "Atomic-Number": 10,
        Weight: 20.18,
        Group: 18,
        Period: 2,
        Family: "Noble Gases",
        Category: "noble gas",
    },
];

export const period3LeftMostElements: IElement[] = [
    {
        Element: "Sodium",
        Symbol: "Na",
        "Atomic-Number": 11,
        Weight: 22.99,
        Group: 1,
        Period: 3,
        Family: "Alkali Metals",
        Category: "alkali metal",
    },
    {
        Element: "Magnesium",
        Symbol: "Mg",
        "Atomic-Number": 12,
        Weight: 24.305,
        Group: 2,
        Period: 3,
        Family: "Alkaline Earth Metals",
        Category: "alkaline earth metal",
    },
];

export const period3RightMostElements: IElement[] = [
    {
        Element: "Aluminium",
        Symbol: "Al",
        "Atomic-Number": 13,
        Weight: 26.982,
        Group: 13,
        Period: 3,
        Family: "Post-Transition Metals",
        Category: "post-transition metal",
    },
    {
        Element: "Silicon",
        Symbol: "Si",
        "Atomic-Number": 14,
        Weight: 28.085,
        Group: 14,
        Period: 3,
        Family: "Metalloids",
        Category: "metalloid",
    },
    {
        Element: "Phosphorus",
        Symbol: "P",
        "Atomic-Number": 15,
        Weight: 30.974,
        Group: 15,
        Period: 3,
        Family: "Reactive Nonmetals",
        Category: "polyatomic nonmetal",
    },
    {
        Element: "Sulfur",
        Symbol: "S",
        "Atomic-Number": 16,
        Weight: 32.06,
        Group: 16,
        Period: 3,
        Family: "Reactive Nonmetals",
        Category: "polyatomic nonmetal",
    },
    {
        Element: "Chlorine",
        Symbol: "Cl",
        "Atomic-Number": 17,
        Weight: 35.45,
        Group: 17,
        Period: 3,
        Family: "Reactive Nonmetals",
        Category: "diatomic nonmetal",
    },
    {
        Element: "Argon",
        Symbol: "Ar",
        "Atomic-Number": 18,
        Weight: 39.948,
        Group: 18,
        Period: 3,
        Family: "Noble Gases",
        Category: "noble gas",
    },
];

export const lanthanidesElements: IElement[] = lanthanidesElements1.map((el) => ({
    ...el,
    Category: "lanthanide",
}));

export const actinidesElements: IElement[] = actinidesElements1.map((el) => ({
    ...el,
    Category: "actinide",
}));
