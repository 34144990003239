import {useMutation, UseMutationResult, useQuery, UseQueryResult} from "react-query";
import axiosInstance from '@/api/_http';
import { ApiResponse } from "@/lib/response";


interface User {
  id: number;
  isAdmin: boolean ;
}

interface UserData {
  first_name: string;
  last_name: string;
  email: string;
  institution: string;
  sector_id: number;
  role_id: number;
  country_id: number;
  profile_status: number;
  role_label: string;
  sector_label: string;
  country_label: string;
  approval_status: number;
  is_email_verified: boolean;
  is_admin: boolean;
  created_at: string; // ISO 8601 format for dates (e.g., "2023-01-01T00:00:00Z")
  user_type: number;
}

interface GenericResponse {

}


// Custom hook to manage fetching notification types and mutating notifications
export const useAdminManager = () => {

  // Refactor to use a function that returns useQuery result
  const useFetchAdminData = (userID: number) => {
    return useQuery<ApiResponse<User[]>, Error>(
      ['fetch-admin', userID], // Unique query key, including typeId
        async (userID) => {
        const { data } = await axiosInstance.get(`/api/admin?user_id=${userID}`);
        return data; // Fetch unread notifications
      }, // Use the fetch function with typeId as an argument
    );
  };

  // function useFetchUsers(): UseQueryResult<ApiResponse<UserData[]>, Error> {
  //   return useQuery<ApiResponse<UserData[]>, Error>('fetchUsers', async () => {
  //     const { data } = await axiosInstance.get<ApiResponse<UserData[]>>('/api/2.0/admin/users');
  //     return data;
  //   });
  // }
    const useFetchUsers = ({
                               page,
                               limit,
                               filters,
                               sorting,
                               searchKey,
                           }: {
        page: number;
        limit: number;
        filters?: any;
        sorting?: any;
        searchKey?: string;
    }) => {
        return useQuery(
            ['users', page, limit, filters, sorting, searchKey],
            async () => {
                const response = await axiosInstance.get('/api/2.0/admin/users', {
                    params: {
                        page,
                        limit,
                        searchKey,
                        ...filters,
                        sorting: sorting
                            ? sorting.map((s: any) => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
                            : undefined,
                    },
                });
                return response.data;
            },
            { keepPreviousData: true }
        );
    };


  function useUpdateApprovalStatus(): UseMutationResult<
      ApiResponse<GenericResponse>,
      Error,
      { user_id: number, approval_status: number }
  > {
    return useMutation<ApiResponse<GenericResponse>, Error, { user_id: number, approval_status: number }>(
        async ({ user_id, approval_status }) => {
          const { data: response } = await axiosInstance.put('/api/2.0/admin/user/approval-status', { user_id:user_id, approval_status: approval_status });
          return response;
        }
    );
  }

  return {
    useFetchAdminData,
    useFetchUsers,
    useUpdateApprovalStatus
  };
};
