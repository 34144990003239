import React, {HTMLAttributes, useEffect, useState} from 'react'

import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {Link, useNavigate} from 'react-router-dom';
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { PasswordInput } from '@/components/password-input'
import {useAuthManager} from "@/hooks/use-auth-hook";
import { Alert, AlertDescription } from '@/components/ui/alert'

type UserAuthFormProps = HTMLAttributes<HTMLDivElement>

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Please enter your email' })
    .email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(1, {
      message: 'Please enter your password',
    })
    .min(7, {
      message: 'Password must be at least 7 characters long',
    }),
})

export function UserAuthForm({ className, ...props }: UserAuthFormProps) {
    const [email, setEmail] = useState('')
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [invalidCredentials, setInvalidCredentials] = React.useState(false);
    const [accountApprovalStatus, setAccountApprovalStatus] = React.useState(false);
    const [unknownError, setUnknownError] = React.useState(false);
    const [resentVerification, setResentVerification] = React.useState(false);

      const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
          email: '',
          password: '',
        },
      })

    const navigate = useNavigate();

    // Extract the signInMutation hook from useAuthManager
    const { useSignInMutation, useResendVerification } = useAuthManager();
    const { mutate: signIn, isLoading } = useSignInMutation();
    const { mutate: resendEmailVerification } = useResendVerification();

    const handleResendVerificationEmail = () => {

        const onSuccess = () => {
            setResentVerification(true)
            setIsEmailVerified(false);
        }

        const onError = () => {
            setIsEmailVerified(true);
        }

        resendEmailVerification({email}, {onSuccess, onError})

    };

    // Auto redirection on being select remember me
    useEffect(() => {
        if (localStorage.getItem('remember_me') === "true") {
            navigate('/dashboard');
            return
        }

        localStorage.clear()
    }, []);


    const resetAllErrorsMessage = () => {
        setIsEmailVerified(false);
        setInvalidCredentials(false);
        setUnknownError(false);
        setAccountApprovalStatus(false);
    }

    function onSubmit(data: z.infer<typeof formSchema>) {

        resetAllErrorsMessage()

        setEmail(data.email)

        if (!data.email || !data.password) {
            return
        }

        const onSuccess = (response: any) => {
            if (response.code === 200) {
                localStorage.setItem('id', response.data.id);
                localStorage.setItem('user', response.data.user);
                localStorage.setItem('email', response.data.email)
                localStorage.setItem('is_admin', response.data.is_admin)
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);
                localStorage.setItem("is_user_logged_in", "true");
                localStorage.setItem('remember_me', "true");
                localStorage.setItem('user_type', response.data.user_type);

                //Old code support
                localStorage.setItem("isLoggedIn", "true");
                localStorage.setItem("isAdmin", response.data.is_admin);
                localStorage.setItem("userID", response.data.id);

                navigate('/');
            }
        }

        const onError = (error: any) => {
            if (error?.response?.data?.code === 409) {
                setIsEmailVerified(true)
                return
            }

            if (error?.response?.data?.code === 403) {
                setInvalidCredentials(true)
                return;
            }

            if (error?.response?.data?.code === 406) {
                setAccountApprovalStatus(true)
                return;
            }


            setUnknownError(true)
            return;
        }

        if (typeof data.email === 'string' && typeof data.password === 'string') {

            setEmail(data.email);

            signIn({ email: data.email, password: data.password }, {onSuccess, onError});
        }
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
        <div className="mt-4">
            {/* Verification email sent */}
            {resentVerification && (
                <Alert className="bg-green-700 text-white">
                    <AlertDescription>
                        Verification email sent. Please check your inbox, or spam/junk folder, to verify your account.
                    </AlertDescription>
                </Alert>
            )}

            {/* Unknown error */}
            {unknownError && (
                <Alert className="bg-red-700 text-white">
                    <AlertDescription>
                        An unexpected error occurred. For any assistance, you can reach out to us at {' '}
                        <a
                            href="mailto:mcdcadmin@clemson.edu"
                            className="underline font-bold text-blue-300"
                        >
                            mcdcadmin@clemson.edu
                        </a>.
                    </AlertDescription>
                </Alert>
            )}

            {/* Unapproved account */}
            {accountApprovalStatus && (
                <Alert className="bg-yellow-700 text-white">
                    <AlertDescription>
                        Your account is awaiting approval. For any assistance, you can reach out to us at {' '}
                        <a
                            href="mailto:mcdcadmin@clemson.edu"
                            className="underline font-bold text-blue-300"
                        >
                            mcdcadmin@clemson.edu
                        </a>.
                    </AlertDescription>
                </Alert>
            )}

            {/* Invalid credentials */}
            {invalidCredentials && (
                <Alert className="bg-red-700 text-white">
                    <AlertDescription>
                        Invalid username or password. Please try again.
                    </AlertDescription>
                </Alert>
            )}

            {/* Email unverified */}
            {isEmailVerified && (
                <Alert className="bg-yellow-700 text-white">
                    <AlertDescription>
                        It looks like you haven't verified your email address yet. Didn’t receive it?{' '}
                        <button
                            onClick={handleResendVerificationEmail}
                            className="underline font-bold text-black"
                        >
                            Send
                        </button>
                    </AlertDescription>
                </Alert>
            )}
        </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid gap-2'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem className='space-y-1'>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder='name@example.com' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem className='space-y-1'>
                  <div className='flex items-center justify-between'>
                    <FormLabel>Password</FormLabel>
                    <Link
                      to='/forgot-password'
                      className='text-sm font-medium text-muted-foreground hover:opacity-75'
                    >
                      Forgot password?
                    </Link>
                  </div>
                  <FormControl>
                    <PasswordInput placeholder='********' {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className='mt-2' disabled={isLoading}>
              Sign in
            </Button>

            {/*<div className='relative my-2'>*/}
            {/*  <div className='absolute inset-0 flex items-center'>*/}
            {/*    <span className='w-full border-t' />*/}
            {/*  </div>*/}
            {/*  <div className='relative flex justify-center text-xs uppercase'>*/}
            {/*    <span className='bg-background px-2 text-muted-foreground'>*/}
            {/*      Or continue with*/}
            {/*    </span>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className='flex items-center gap-2'>*/}
            {/*  <Button*/}
            {/*    variant='outline'*/}
            {/*    className='w-full'*/}
            {/*    type='button'*/}
            {/*    disabled={isLoading}*/}
            {/*  >*/}
            {/*    <IconBrandGithub className='h-4 w-4' /> GitHub*/}
            {/*  </Button>*/}
            {/*  <Button*/}
            {/*    variant='outline'*/}
            {/*    className='w-full'*/}
            {/*    type='button'*/}
            {/*    disabled={isLoading}*/}
            {/*  >*/}
            {/*    <IconBrandFacebook className='h-4 w-4' /> Facebook*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </div>
        </form>
      </Form>
    </div>
  )
}
