// @ts-nocheck

import React, { HTMLAttributes, useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import {Link, useNavigate} from "react-router-dom";
import {PasswordInput} from "@/components/password-input";
import {useAuthManager} from "@/hooks/use-auth-hook";
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";

type ResetFormProps = HTMLAttributes<HTMLDivElement>

const formSchema = z.object({
  password: z
      .string()
      .min(1, {
        message: 'Please enter your password',
      })
      .min(7, {
        message: 'Password must be at least 7 characters long',
      }),
})

export function ResetForm({ className, ...props }: ResetFormProps) {

    const [open, setOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle')

    const {useResetPassword} = useAuthManager()
    const { mutate: resetPassword, isLoading } = useResetPassword();
    const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: '',
    },
  })

  function onSubmit(data: z.infer<typeof formSchema>) {

      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token") as string;

      resetPassword(
          { token: token, password: data.password },
          {
              onSuccess: () => {
                  setStatus('success')
                  setOpen(true)
                  setTimeout(() => navigate('/sign-in'), 3000);
              },
              onError: () => {
                  setStatus('error')
                  setOpen(true)
              },
          }
      );
  }

  return (
    <div className={cn('grid gap-6', className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className='grid gap-2'>
            <FormField
                control={form.control}
                name='password'
                render={({ field }) => (
                    <FormItem className='space-y-1'>
                      <FormControl>
                        <PasswordInput placeholder='********' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                )}
            />
            <Button className='mt-2' disabled={isLoading}>
              Continue
            </Button>
          </div>
        </form>
      </Form>

        <div>
            {status === 'success' && (
                <Dialog open={open} onOpenChange={setOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Email Verified</DialogTitle>
                        </DialogHeader>
                        <p>You have reset your password successfully. Redirecting to Sign In..</p>
                    </DialogContent>
                </Dialog>
            )}

            {status === 'error' && (
                <Dialog open={open} onOpenChange={setOpen}>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>Verification Failed</DialogTitle>
                        </DialogHeader>
                        <p>Failed to reset the password. Please try again. For any assistance, you can reach out to us at {' '}
                            <a
                                href="mailto:mcdcadmin@clemson.edu"
                                className="underline font-bold text-blue-300"
                            >
                                mcdcadmin@clemson.edu
                            </a>.</p>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    </div>
  )
}
