import {
  AlertTriangle,
  ArrowRight,
  Check,
  ChevronLeft,
  ChevronRight,
  CircuitBoardIcon,
  Command,
  CreditCard,
  File,
  FileText,
  HelpCircle,
  Image,
  Laptop,
  LayoutDashboardIcon,
  Loader2,
  LogIn,
    LogOut,
  LucideIcon,
  Moon,
  MoreVertical,
  Pizza,
  Plus,
  Settings,
  SunMedium,
  Trash,
  User,
  User2Icon,
  UserX2Icon,
  X,
  Database,
  DatabaseBackup,
  Store,
  Package,
  UserCog,
  ListFilter,
  Filter,
  ArrowDownNarrowWide,
  ArrowUpNarrowWide,
  BellDot,
    Newspaper,

} from "lucide-react";
import {
  IconBarrierBlock,
  IconBrowserCheck,
  IconBug,
  IconChecklist,
  IconError404,
  IconHelp,
  IconLayoutDashboard,
  IconLock,
  IconLockAccess,
  IconMessages,
  IconNotification,
  IconPackages,
  IconPalette,
  IconServerOff,
  IconSettings,
  IconTool,
  IconUserCog,
  IconUserOff,
  IconUsers,
    IconBell,
    IconBuildingFactory
} from '@tabler/icons-react'

import {IconProps} from "@radix-ui/react-icons/dist/types";

export type Icon = LucideIcon;

export const Icons = {
  dashboard: LayoutDashboardIcon,
  logo: Command,
  login: LogIn,
  close: X,
  profile: User2Icon,
  spinner: Loader2,
  kanban: CircuitBoardIcon,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  trash: Trash,
  employee: UserX2Icon,
  post: FileText,
  page: File,
  media: Image,
  settings: Settings,
  billing: CreditCard,
  ellipsis: MoreVertical,
  add: Plus,
  warning: AlertTriangle,
  user: User,
  arrowRight: ArrowRight,
  help: HelpCircle,
  pizza: Pizza,
  sun: SunMedium,
  moon: Moon,
  laptop: Laptop,
  check: Check,
  database: Database,
  databaseBackup: DatabaseBackup,
  store: Store,
  package: Package,
  userCog: UserCog,
  listFilter: ListFilter,
  filter: Filter,
  arrowDownNarrowWide: ArrowDownNarrowWide,
  arrowUpNarrowWide: ArrowUpNarrowWide,
  google: (props: IconProps) => (
      <svg role="img" viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            d="M12.48 10.92v3.28h7.84c-.24 1.84-.853 3.187-1.787 4.133-1.147 1.147-2.933 2.4-6.053 2.4-4.827 0-8.6-3.893-8.6-8.72s3.773-8.72 8.6-8.72c2.6 0 4.507 1.027 5.907 2.347l2.307-2.307C18.747 1.44 16.133 0 12.48 0 5.867 0 .307 5.387.307 12s5.56 12 12.173 12c3.573 0 6.267-1.173 8.373-3.36 2.16-2.16 2.84-5.213 2.84-7.667 0-.76-.053-1.467-.173-2.053H12.48z"
        />
      </svg>
  ),
  logOut: LogOut,
  bellDot: BellDot,
  newsPaper: Newspaper,
  iconNotifications: IconNotification,
  iconBell: IconBell,
  iconBuildingFactory: IconBuildingFactory
};
