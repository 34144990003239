import { z } from 'zod'

const userStatusSchema = z.union([
  z.literal('active'),
  z.literal('inactive'),
  z.literal('invited'),
  z.literal('suspended'),
])
export type UserStatus = z.infer<typeof userStatusSchema>

export const userDataSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().regex(
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      "Invalid email format"
  ),
  institution: z.string(),
  sector_id: z.number(),
  role_id: z.number(),
  country_id: z.number(),
  profile_status: z.number(),
  role_label: z.string(),
  sector_label: z.string(),
  country_label: z.string(),
  approval_status: z.number(),
  is_email_verified: z.boolean(),
  is_admin: z.boolean(),
  created_at: z.string().refine((date) => !isNaN(Date.parse(date)), {
    message: 'Invalid date format',
  }), // Validates ISO 8601 date format
  user_type: z.number(),
  approval_status_label: z.string(),
  user_type_label: z.string(),
});

// Infer the TypeScript type from the schema
export type UserData = z.infer<typeof userDataSchema>;
// Define a Zod schema for an array of UserData
export const userListSchema = z.array(userDataSchema);


const userApprovalStatus = z.union([
  z.literal('1'),
  z.literal('2'),
  z.literal('3'),
])
export type UserApprovalStatus = z.infer<typeof userApprovalStatus>

// Define the Zod schema for user types
export const userTypeSchema = z.union([
  z.literal('admin'),
  z.literal('standard'),
  z.literal('beta'),
]);

// Infer the TypeScript type from the schema
export type UserType = z.infer<typeof userTypeSchema>;