// @ts-nocheck

import React, { HTMLAttributes, useState } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useAuthManager } from "@/hooks/use-auth-hook"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogClose,
} from "@/components/ui/dialog"

type ForgotFormProps = HTMLAttributes<HTMLDivElement>

const formSchema = z.object({
  email: z
      .string()
      .min(1, { message: 'Please enter your email' })
      .email({ message: 'Invalid email address' }),
})

export function ForgotForm({ className, ...props }: ForgotFormProps) {
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle')
  const [open, setOpen] = useState<boolean>(false)

  const { useForgotPassword } = useAuthManager()
  const { mutate: forgotPassword, isLoading } = useForgotPassword()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: { email: '' },
  })

  function onSubmit(data: z.infer<typeof formSchema>) {
    forgotPassword(
        { email: data.email },
        {
          onSuccess: () => {
            setStatus('success')
            setOpen(true)
          },
          onError: () => {
            setStatus('error')
            setOpen(true)
          },
        }
    )
  }

  return (
      <div className={cn('grid gap-6', className)} {...props}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-2">
              <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                      <FormItem className="space-y-1">
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="name@example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                  )}
              />
              <Button className="mt-2" disabled={isLoading}>
                {isLoading ? 'Processing...' : 'Continue'}
              </Button>
            </div>
          </form>
        </Form>

        {/* Success Dialog */}
        {status === 'success' && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Password Reset</DialogTitle>
                </DialogHeader>
                <p>
                  A password reset link has been sent to your email. Please check your inbox, or spam/junk folder, and
                  follow the instructions.
                </p>
              </DialogContent>
            </Dialog>
        )}

        {/* Error Dialog */}
        {status === 'error' && (
            <Dialog open={open} onOpenChange={setOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Password Reset Failed</DialogTitle>
                </DialogHeader>
                <p>We could not process your request right now. Please try again. For any assistance, you can reach out to us at {' '}
                    <a
                        href="mailto:mcdcadmin@clemson.edu"
                        className="underline font-bold text-blue-300"
                    >
                        mcdcadmin@clemson.edu
                    </a>.</p>
              </DialogContent>
            </Dialog>
        )}
      </div>
  )
}
