import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import PeriodicTable from "@/components/perodic-table";

export default function Dashboard() {
  return (
      <ScrollArea className="h-full">
        <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
          <div className="flex items-center justify-between space-y-2">
            <h2 className="text-3xl font-bold tracking-tight">
              Hello👋, Welcome to the Virtual Center for Nuclear Waste Glass Science!
            </h2>
          </div>
          <Tabs defaultValue="overview" className="space-y-4">
            <TabsContent value="overview" className="space-y-4">
              <div className="flex">
                <Card className="w-full">
                  <CardHeader>
                    <CardTitle>Overview</CardTitle>
                  </CardHeader>
                  <CardContent className="">
                    This is the first online, open-access repository of nuclear waste
                    glass data called the Virtual Center for Nuclear Waste Glass Science. The primary goal of the database is
                    to consolidate the data from decades of research into a single source that can be used by glass scientists
                    from around the world to study radioactive waste forms.
                  </CardContent>
                </Card>
              </div>
              {/*<div className="flex flex-col items-center hidden lg:flex">*/}
              {/*  <PeriodicTable />*/}
              {/*</div>*/}
            </TabsContent>
          </Tabs>
        </div>
      </ScrollArea>
  );
}
