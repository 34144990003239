import {
  IconCash,
  IconShield,
  IconUsersGroup,
  IconUserShield,
} from '@tabler/icons-react'
import {UserStatus, UserApprovalStatus, UserType} from './schema'

export const callTypes = new Map<UserStatus, string>([
  ['active', 'bg-teal-100/30 text-teal-900 dark:text-teal-200 border-teal-200'],
  ['inactive', 'bg-neutral-300/40 border-neutral-300'],
  ['invited', 'bg-sky-200/40 text-sky-900 dark:text-sky-100 border-sky-300'],
  [
    'suspended',
    'bg-destructive/10 dark:bg-destructive/50 text-destructive dark:text-primary border-destructive/10',
  ],
])

export const approvalStatusStyles = new Map<UserApprovalStatus, string>([
  [
    '1',
    'bg-yellow-100/40 text-yellow-900 dark:text-yellow-200 border-yellow-300',
  ],
  [
    '2',
    'bg-green-100/30 text-green-900 dark:text-green-200 border-green-300',
  ],
  [
    '3',
    'bg-red-100/40 text-red-900 dark:text-red-200 border-red-300',
  ],
]);

// Define the Map for user type styles
export const userTypeStyles = new Map<UserType, string>([
  [
    'admin',
    'bg-indigo-100/30 text-indigo-900 dark:text-indigo-200 border-indigo-300',
  ],
  [
    'standard',
    'bg-gray-100/40 text-gray-900 dark:text-gray-200 border-gray-300',
  ],
  [
    'beta',
    'bg-purple-100/30 text-purple-900 dark:text-purple-200 border-purple-300',
  ],
]);

export const userTypes = [
  {
    label: 'admin',
    value: 0,
    icon: IconShield,
  },
  {
    label: 'standard',
    value: 1,
    icon: IconUserShield,
  },
  {
    label: 'beta',
    value: 2,
    icon: IconUsersGroup,
  },
  {
    label: 'No DB access',
    value: 3,
    icon: IconUsersGroup,
  },
] as const
