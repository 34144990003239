import {createBrowserRouter, Navigate} from "react-router-dom";
import Layout from "@/components/Layout";
import Welcome from "@/components/Landing";
import Profile from "@/components/Profile";
import NewsUpdates from "@/components/NewsUpdates";
import Database from "@/pages/Database/Database";
import Admin from "@/pages/Admin/Admin";

import ForgotPassword from "@/pages/auth2/forgot-password"
import ResetPassword from "@/pages/auth2/reset-password"
import EmailVerification from "@/pages/auth2/email-verification";
import ContactUs from "@/components/ContactUs";
import SignIn from "@/pages/auth2/sign-in";
import NotFoundError from "@/pages/errors/not-found-error";
import GeneralError from "@/pages/errors/general-error";
import SignUp from "@/pages/auth2/sign-up";
import Dashboard from "@/pages/dashboard";
import GeneralRoutes from "@/routes/general-routes";
import MainLayout from "@/pages/layout";
import Users from "src/pages/admin2/users";
import GlassPyMain from "@/pages/GlassPy/GlassPyMain";
import GlassDatabaseUpdate from "src/pages/admin2/glass-database-update";
import GlassDatabaseView from "src/pages/general/glass-database-view";
import {SignOut} from "@/pages/auth2/sign-out";
import HelpCenterPage from "@/pages/others/help-center";
import JobsQueuePage from "@/pages/admin2/jobs-queue";
import MaintenanceError from "@/pages/errors/maintenance-error";
import MlModels from "@/pages/general/ml-models";

const routers = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to={"/sign-in"} />,
        errorElement: <MaintenanceError />,
    },
    {
        path: "/sign-in",
        element: <SignIn />,
        errorElement: <GeneralError />,
    },
    {
        path: "/sign-out",
        element: <SignOut />,
        errorElement: <GeneralError />,
    },
    {
        path: "/sign-up",
        element: <SignUp />,
        errorElement: <GeneralError />,
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword />,
        errorElement: <GeneralError />,
    },
    {
        path: "/reset-password",
        element: <ResetPassword />,
        errorElement: <GeneralError />,
    },
    {
        path: "/sign-up/verification",
        element: <EmailVerification />,
        errorElement: <GeneralError />,
    },
    {
        path: "/dashboard",
        element: <MainLayout children={<Dashboard />}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/general/glass-database",
        element: <MainLayout children={<GlassDatabaseView/>}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/general/ml-models",
        element: <MainLayout children={<MlModels />}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/admin/glass-database/update",
        element: <MainLayout children={<GlassDatabaseUpdate />}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/admin/jobs-queue",
        element: <MainLayout children={<JobsQueuePage />}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/admin/users",
        element: <MainLayout children={<Users />}/>,
        errorElement: <GeneralError />,
    },
    {
        path: "/others/contact-us",
        element: <MainLayout children={<HelpCenterPage />}/>,
        errorElement: <GeneralError />
    },
]);

export default routers;