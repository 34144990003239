import React, { useState, useEffect } from 'react';
import { Input } from '@/components/ui/input';
import { IconDownload, IconSearch } from '@tabler/icons-react';
import { Button } from '@/components/ui/button';
import { LoadingSpinner } from "@/components/loader/spinner";

interface DataTableToolbarProps {
    searchKey: string;
    setSearchKey: (value: string) => void;
    databaseDataDownloadURL: string;
    isLoading: boolean;
    handleDownload: () => void;
}

export function DataTableToolbar({
                                     searchKey,
                                     setSearchKey,
                                     databaseDataDownloadURL,
                                     isLoading,
                                     handleDownload
                                 }: DataTableToolbarProps) {

    // Local state for the search input value
    const [inputValue, setInputValue] = useState(searchKey);

    // Debounce effect: only call the backend once the user stops typing for 500ms
    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchKey(inputValue);
        }, 500);

        return () => clearTimeout(timer);
    }, [inputValue, setSearchKey]);

    useEffect(() => {
        if (!isLoading && databaseDataDownloadURL) {
            window.open(databaseDataDownloadURL, '_blank');
        }
    }, [databaseDataDownloadURL]);

    return (
        <div className="flex flex-col gap-2 mt-4 bg-gray-100 p-4 rounded-lg shadow-md">
            <div className="flex flex-row gap-2 items-center justify-between">
                <div className="flex flex-row gap-2 items-center">
                    <IconSearch stroke={2} />
                    <Input
                        placeholder="Search by meta data..."
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)} // Update local state
                        className="h-8 w-[250px] bg-white border border-gray-300 rounded-md px-2"
                    />
                </div>
                <Button
                    variant="outline"
                    size="sm"
                    onClick={() => handleDownload()}
                >
                    {isLoading ? (
                        <div className="flex items-center justify-center bg-opacity-75 z-10">
                            Downloading...<LoadingSpinner />
                        </div>
                    ) : (
                        <>
                            Download <IconDownload />
                        </>
                    )}
                </Button>
            </div>
        </div>
    );
}
