import { Main } from '@/components/layout/main';
import BreadCrumb from '@/components/breadcrumb';
import ContactUsForm from "@/pages/others/help-center/components/contact-us-form";

const breadcrumbItems = [{ title: 'Help Center', link: '/others/contact-us' }];

export default function HelpCenterPage() {
    return (
        <Main>
            <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
                <BreadCrumb items={breadcrumbItems}/>
            </div>
            <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap w-full'>
                <div>
                    <h2 className='text-2xl font-bold tracking-tight'>Contact Us</h2>
                    <p className='text-muted-foreground'>
                        Feel free to use the form below to contact us whenever you need assistance.
                    </p>
                </div>
            </div>
            <div className="flex items-center justify-center mt-10">
                <ContactUsForm/>
            </div>
        </Main>
    );
}
