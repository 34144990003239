import { DataTableColumnHeader } from './data-table-column-header';

export const columns = (columns: {
  database_id: number;
  oxide_name?: string;
  property_name?: string;
  meta_data_name?: string;
}[]) => {
  // Add the sno column as the first column
  const snoColumn = {
    accessorKey: 'sno',
    header: ({ column }) => (
        <DataTableColumnHeader
            column={column}
            title="S.No"
        />
    ),
    cell: ({ row }) => (
        <div className="w-fit text-nowrap">
          {row.index + 1} {/* Index starts from 0; +1 for serial number */}
        </div>
    ),
    enableSorting: true,
    enableHiding: false,
  };

  // Map the remaining columns
  const otherColumns = columns.map((col) => {
    let key = col.oxide_name
        ? col.oxide_name
        : col.property_name
            ? col.property_name
            : col.meta_data_name;
    if (!key) key = "Unknown";

    return {
      accessorKey: key,
      header: ({ column }) => (
          <DataTableColumnHeader
              column={column}
              title={key.charAt(0).toUpperCase() + key.slice(1).replace('_', ' ')}
          />
      ),
      cell: ({ row }) => {
        const value = row.getValue(key);

        // Check if the value is a link
        const isLink = typeof value === 'string' && (value.startsWith('http://') || value.startsWith('https://'));

        return (
            <div className="w-fit text-nowrap">
              {isLink ? (
                  <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
                    {value}
                  </a>
              ) : (
                  value
              )}
            </div>
        );
      },
      enableSorting: true,
      enableHiding: false,
    };
  });

  // Prepend the sno column to the other columns
  return [snoColumn, ...otherColumns];
};
