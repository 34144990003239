import { Card } from '@/components/ui/card'
import AuthLayout from '../auth-layout'
import { UserAuthForm } from './components/user-auth-form'
import {Link} from "react-router-dom";
import {Separator} from "@/components/ui/separator";

export default function SignIn() {
  return (
    <AuthLayout>
      <Card className='p-6'>
        <div className='flex flex-col space-y-2 text-center'>
          <h1 className='text-2xl font-bold tracking-tight'>Sign In</h1>
          <p className='text-sm text-muted-foreground'>
            Enter your password and username below.
          </p>
        </div>
        <UserAuthForm/>
        <p className='mt-4 mb-4 px-8 text-center text-sm text-muted-foreground'>
          Don't have an account?{' '}
          <Link
              to='/sign-up'
              className='underline underline-offset-4 hover:text-primary'
          >
            Sign up
          </Link>
          .
        </p>
        <Separator/>
        <p className='mt-4 px-8 text-center text-sm text-muted-foreground'>
          By clicking login, you agree to our{' '}
          <a
              href='/terms'
              className='underline underline-offset-4 hover:text-primary'
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
              href='/privacy'
              className='underline underline-offset-4 hover:text-primary'
          >
            Privacy Policy
          </a>
          .
        </p>
      </Card>
    </AuthLayout>
  )
}
