import {useMutation, UseMutationResult, useQuery, UseQueryResult} from 'react-query';
import axiosInstance from '../api/_http';
import { ApiResponse } from '@/lib/response';
import axios from "axios";

const directAxios = axios.create({
    // No baseURL, so it won't prepend anything
});


type SortOption = {
    id: string;
    desc: boolean;
};

type RangeFilter = {
    min: any;
    max: any;
    inclusive: boolean;
};

type Filter = {
    key: string;
    operation: string;
    value?: any;
    range?: RangeFilter;
    include?: boolean;
};

type FetchDatabaseDataParams = {
    database_id: number;
    filters?: {
        compositions?: Filter[];
        properties?: Filter[];
    };
    meta_data_search_key?: string;
    limit?: number;
    page?: number;
    sorting?: SortOption[];
};

type FetchDatabaseDataDownloadURLParams = {
    database_id: number;
    filters?: {
        compositions?: Filter[];
        properties?: Filter[];
    };
    meta_data_search_key?: string;
    limit?: number;
    page?: number;
    sorting?: SortOption[];
    totalRows: number;
};

type FetchDatabaseByBinSizeDataParams = {
    database_id: number;
    filters?: {
        compositions?: Filter[];
        properties?: Filter[];
    };
    bin_size: number;
    x_axis: string;
    y_axis: string;
};

type Pagination = {
    page: number;
    limit: number;
    total_items: number;
    total_pages: number;
    has_next_page: boolean;
    has_prev_page: boolean;
};

type FetchDatabaseDataResponse = {
    download_url: string;
};
function serializeSorting(sorting?: SortOption[]): string | undefined {
    if (!sorting || sorting.length === 0) return undefined;
    return sorting.map(({ id, desc }) => `${id}:${desc ? 'desc' : 'asc'}`).join(',');
}

function serializeFilters(filters?: { compositions?: Filter[]; properties?: Filter[] }) {
    if (!filters) return '';
    return JSON.stringify(filters);
}


export function useDatabaseManager() {

    function useFetchDatabase(user_id: number): UseQueryResult<ApiResponse<{
        id: number;
        name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            id: number;
            name: string;
        }[]>, Error>(['fetchDatabase', user_id], async () => {
            const { data } = await axiosInstance.get<ApiResponse<{
                id: number;
                name: string;
            }[]>>(`/api/2.0/databases?user_id=${user_id}`);
            return data;
        },{
            enabled: Boolean(user_id),
            keepPreviousData: true, // Maintain current data while new data is fetched
            staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
            cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
        });
    }

    function useFetchDatabaseColumns(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        oxide_name?: string;
        property_name?: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            oxide_name?: string;
            property_name?: string;
        }[]>, Error>(['fetchDatabaseColumns', glass_database_id], async () => {
            const { data } = await axiosInstance.get(`/api/2.0/databases/columns?glass_database_id=${glass_database_id}`);
            return data;
        },
        {
            enabled: Boolean(glass_database_id),
            keepPreviousData: true, // Maintain current data while new data is fetched
            staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
            cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
        }
        );
    }

    function useFetchDatabaseProperties(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        property_name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            property_name: string;
        }[]>, Error>(['fetchDatabaseProperties', glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/properties?glass_database_id=${glass_database_id}`);
                return data;
            },
            {
                enabled: Boolean(glass_database_id),
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

    function useFetchDatabaseOxides(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        oxide_name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            oxide_name: string;
        }[]>, Error>(['fetchDatabaseOxides', glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/oxides?glass_database_id=${glass_database_id}`);
                return data;
            },
            {
                enabled: Boolean(glass_database_id),
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

    function useFetchDatabaseElements(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        element_name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            element_name: string;
        }[]>, Error>(['fetchDatabaseElements', glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/elements?glass_database_id=${glass_database_id}`);
                return data;
            },
            {
                enabled: Boolean(glass_database_id),
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

    function useMutateGlassDatabaseData(): UseMutationResult<
        ApiResponse<null>,
        Error,
        { database_id: number; payload: any }
    > {
        return useMutation<ApiResponse<null>, Error, { database_id: number; payload: any }>(
            async ({ database_id, payload }) => {
                const { data: response } = await axiosInstance.post('/api/2.0/databases/data', {
                    database_id,
                    payload
                },
                {
                    headers: {
                        'X-Compress': 'true',
                    },
                });
                return response;
            }
        );
    }

    function useMutateGlassDatabaseDataJob(): UseMutationResult<
        ApiResponse<null>,
        Error,
        { database_id: number; compositions: string[], properties: string[], meta_data: string[], object_name: string, user_id: number }
    > {
        return useMutation<ApiResponse<null>, Error, { database_id: number; compositions: string[], properties: string[], meta_data: string[], object_name: string, user_id: number }>(
            async ({ database_id, compositions, properties, meta_data, object_name, user_id }) => {
                const { data: response } = await axiosInstance.post('/api/2.0/databases/job-queue', {
                        database_id,
                        compositions,
                        properties,
                        meta_data,
                        object_name,
                        id: user_id,
                    },
                    {
                        headers: {
                            'X-Compress': 'true',
                        },
                    });
                return response;
            }
        );
    }

    function useFetchDatabaseData(params: FetchDatabaseDataParams) {
        return useQuery<FetchDatabaseDataResponse, Error>(
            ['fetchDatabaseData', params],
            async () => {
                const response = await axiosInstance.get('/api/2.0/databases/data', {
                    params: {
                        database_id: params.database_id,
                        filters: serializeFilters(params.filters),
                        meta_data_search_key: params.meta_data_search_key,
                        limit: params.limit,
                        page: params.page,
                        sorting: serializeSorting(params.sorting),
                    },
                });

                return response.data;
            },
            {
                enabled: Boolean(params.database_id), // Ensures query runs only when database_id is provided
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

    function useFetchDatabaseDataByBinSize(params: FetchDatabaseByBinSizeDataParams) {
        return useQuery<FetchDatabaseDataResponse, Error>(
            ['fetchDatabaseDataByBinSize', params],
            async () => {
                const response = await axiosInstance.get('/api/2.0/databases/graph/data', {
                    params: {
                        database_id: params.database_id,
                        filters: serializeFilters(params.filters),
                        bin_size: params.bin_size,
                        x_axis: params.x_axis,
                        y_axis: params.y_axis,
                    },
                });

                return response.data;
            },
            {
                enabled: Boolean(params.database_id && params.x_axis && params.y_axis), // Ensure all required parameters are provided
                keepPreviousData: true, // Maintain current data while new data is fetched
            }
        );
    }

    function useFetchDatabaseDataDownloadURL(params: FetchDatabaseDataDownloadURLParams) {
        return useQuery<FetchDatabaseDataResponse, Error>(
            ['fetchDatabaseDataDownload', params],
            async () => {
                console.log("params", params);
                const response = await axiosInstance.get('/api/2.0/databases/data/download', {
                    params: {
                        database_id: params.database_id,
                        filters: serializeFilters(params.filters),
                        meta_data_search_key: params.meta_data_search_key,
                        limit: params.limit,
                        page: params.page,
                        sorting: serializeSorting(params.sorting),
                        total_rows: params.totalRows,
                    },
                });

                return response.data;
            },
            {
                enabled: false,
            }
        );
    }

    function useFetchGraphProperties(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        property_name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            property_name: string;
        }[]>, Error>(['fetchGraphProperties', glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/graph/properties?glass_database_id=${glass_database_id}`);
                return data;
            },
            {
                enabled: Boolean(glass_database_id),
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

    function useFetchGraphOxides(glass_database_id: number): UseQueryResult<ApiResponse<{
        database_id: number;
        oxide_name: string;
    }[]>, Error> {
        return useQuery<ApiResponse<{
            database_id: number;
            oxide_name: string;
        }[]>, Error>(['fetchGraphOxides', glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/graph/oxides?glass_database_id=${glass_database_id}`);
                return data;
            },
            {
                enabled: Boolean(glass_database_id),
                keepPreviousData: true, // Maintain current data while new data is fetched
                staleTime: 5 * 60 * 1000, // Data is fresh for 5 minutes, no refetch during this time
                cacheTime: 10 * 60 * 1000, // Cache data for 10 minutes before removing it from the cache
            }
        );
    }

   function useUploadFileToGCS() {
        return useMutation<
            { success: boolean; gcsUrl: string },
            Error,
            File
        >(
            async (file: File) => {

                const currentDateTime = new Date().toISOString();
                const updatedFileName = `${currentDateTime}_${file.name}`;

                const { data } = await axiosInstance.post<{ uploadUrl: string }>(
                    '/api/2.0/databases/gcs/generate-upload-url',
                    { file_name: updatedFileName }
                );

                // @ts-ignore
                const { uploadUrl,  objectName} = data?.data;
                if (!uploadUrl) {
                    throw new Error('No upload URL');
                }

                const response = await directAxios.put(uploadUrl, file, {
                    headers: {
                        "Content-Type": "text/csv"
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round(
                            (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
                        );
                    },
                });
                if (response.status !== 200 && response.status !== 201) {
                    console.log('Upload Complete', response);
                    throw new Error(`Upload failed: ${response.status}`);
                }
                return {
                    success: true,
                    gcsUrl: uploadUrl.split('?')[0],
                    objectName:objectName
                };
            }
        );
    }

    function useFetchJobs(glass_database_id: number, limit: number, page: number): UseQueryResult<ApiResponse<any>, Error> {
        return useQuery<ApiResponse<any>, Error>([['fetchJobs', limit, page], glass_database_id], async () => {
                const { data } = await axiosInstance.get(`/api/2.0/databases/jobs`, {
                    params: {
                        limit: limit,
                        page: page,
                    },
                });
                return data;
            },
            // {
            //     enabled: Boolean(glass_database_id),
            // }
        );
    }

    return {
        useFetchDatabase,
        useFetchDatabaseColumns,
        useMutateGlassDatabaseData,
        useFetchDatabaseData,
        useFetchDatabaseDataByBinSize,
        useFetchDatabaseProperties,
        useFetchDatabaseOxides,
        useFetchDatabaseElements,
        useFetchDatabaseDataDownloadURL,
        useFetchGraphProperties,
        useFetchGraphOxides,
        useUploadFileToGCS,
        useMutateGlassDatabaseDataJob,
        useFetchJobs
    };
}


