// @ts-nocheck

import React from 'react';
import {
    ColumnDef,
    SortingState,
    VisibilityState,
    flexRender,
    useReactTable, getCoreRowModel,
} from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DataTablePagination } from './data-table-pagination';
import { DataTableToolbar } from './data-table-toolbar';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import {LoadingSpinner} from "@/components/loader/spinner";

interface DataTableProps {
  columns: ColumnDef<any>[];
  data: User[];
  isLoading: boolean;
  pagination: {
    page: number;
    limit: number;
    total: number;
    onPageChange: (page: number) => void;
    onLimitChange: (limit: number) => void;
  };
  onFiltersChange: (filters: any) => void;
  onSortingChange: (sorting: SortingState) => void;
  searchKey: string;
  onSearchChange: (value: string) => void;
}

export function UsersTable({
                             columns,
                             data,
                             isLoading,
                             pagination,
                             onFiltersChange,
                             onSortingChange,
                             searchKey,
                             onSearchChange,
                           }: DataTableProps) {

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting: [],
    },
    onSortingChange,
    manualPagination: true,
    manualSorting: true,
    pageCount: Math.ceil(pagination.total / pagination.limit),
    getCoreRowModel: getCoreRowModel(), // Required for basic table functionality
  });

  return (
      <div className="space-y-4">
        <DataTableToolbar
            table={table}
            searchKey={searchKey}
            onSearchChange={onSearchChange}
            onFilterChange={onFiltersChange}
        />
        <ScrollArea className="rounded-md border h-[calc(90vh-220px)]">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <TableHead key={header.id}>
                          {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHead>
                    ))}
                  </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      Loading...
                    </TableCell>
                  </TableRow>
              ) : table.getRowModel().rows.length ? (
                  table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                            <TableCell key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                        ))}
                      </TableRow>
                  ))
              ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      No results.
                    </TableCell>
                  </TableRow>
              )}
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
            {isLoading && (
                <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 z-10">
                    <LoadingSpinner />
                </div>
            )}
        </ScrollArea>
        <DataTablePagination
            table={table}
            onPageChange={(pageIndex) => pagination.onPageChange(pageIndex + 1)} // Adjust for 1-based index
            onLimitChange={(pageSize) => pagination.onLimitChange(pageSize)}
        />
      </div>
  );
}
