// @ts-nocheck

import React, { HTMLAttributes, useState, useEffect } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { PasswordInput } from '@/components/password-input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import { useAuthManager } from '@/hooks/use-auth-hook'
import { usePublicManager } from '@/hooks/use-public-hook'
import {Dialog, DialogContent, DialogHeader, DialogTitle} from "@/components/ui/dialog";

type SignUpFormProps = HTMLAttributes<HTMLDivElement>

const formSchema = z
    .object({
        email: z
            .string()
            .min(1, { message: 'Please enter your email' })
            .email({ message: 'Invalid email address' }),
        firstName: z.string().min(1, { message: 'Please enter your first name' }),
        lastName: z.string().min(1, { message: 'Please enter your last name' }),
        sector: z.string().min(1, { message: 'Please select a sector' }),
        password: z
            .string()
            .min(1, {
                message: 'Please enter your password',
            })
            .min(7, {
                message: 'Password must be at least 7 characters long',
            }),
        confirmPassword: z.string(),
        reason: z.string().min(1, { message: 'Please enter a reason' }),
        institution: z.string().min(1, { message: 'Please enter your institution' }),
        role: z.string().min(1, { message: 'Please select a role' }),
        country: z.string().min(1, { message: 'Please select a country' }),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: "Passwords don't match.",
        path: ['confirmPassword'],
    })

export function SignUpForm({ className, ...props }: SignUpFormProps) {
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            email: '',
            firstName: '',
            lastName: '',
            sector: '',
            password: '',
            confirmPassword: '',
            reason: '',
            institution: '',
            role: '',
            country: '',
        },
    })

    const [open, setOpen] = useState<boolean>(false)
    const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle')

    const { useFetchCountries, useFetchRoles, useFetchSectors } = usePublicManager();
    const { data: countriesData } = useFetchCountries();
    const { data: rolesData } = useFetchRoles();
    const { data: sectorsData } = useFetchSectors();

    const { useSignUp } = useAuthManager();
    const { mutate: signUp, isLoading } = useSignUp();

    const countriesOptions = countriesData?.data.map((country: any) => (
        <SelectItem key={country.id} value={country.id.toString()}>
            {country.label}
        </SelectItem>
    )) || [];

    const rolesOptions = rolesData?.data.map((role: any) => (
        <SelectItem key={role.id} value={role.id.toString()}>
            {role.label}
        </SelectItem>
    )) || [];

    const sectorsOptions = sectorsData?.data.map((sector: any) => (
        <SelectItem key={sector.id} value={sector.id.toString()}>
            {sector.label}
        </SelectItem>
    )) || [];

    function onSubmit(data: z.infer<typeof formSchema>, reset: () => void) {
        const payload = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            password: data.password,
            reason: data.reason,
            institution: data.institution,
            roleID: parseInt(data.role),
            countryID: parseInt(data.country),
            sectorID: parseInt(data.sector),
            identityProviderID: 1,
        };

        signUp(payload, {
            onSuccess: () => {
                setStatus("success")
                form.reset();
                setOpen(true)

            },
            onError: () => {
                setStatus("error")
                setOpen(true)
            },
        });
    }

    return (
        <div className={cn('grid gap-6', className)} {...props}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className='grid gap-2'>
                        <FormField
                            control={form.control}
                            name='email'
                            render={({field}) => (
                                <FormItem className='space-y-1'>
                                    <FormLabel>Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder='name@example.com' {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <div className='grid grid-cols-2 gap-2'>
                            <FormField
                                control={form.control}
                                name='firstName'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>First Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder='First Name' {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name='lastName'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Last Name</FormLabel>
                                        <FormControl>
                                            <Input placeholder='Last Name' {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={form.control}
                            name='reason'
                            render={({field}) => (
                                <FormItem className='space-y-1'>
                                    <FormLabel>Reason</FormLabel>
                                    <FormControl>
                                        <Input placeholder='Why are you signing up?' {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <div className='grid grid-cols-2 gap-2'>
                            <FormField
                                control={form.control}
                                name='role'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Role</FormLabel>
                                        <FormControl>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder='Select a role'/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {rolesOptions}
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name='sector'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Sector</FormLabel>
                                        <FormControl>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder='Select a sector'/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {sectorsOptions}
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className='grid grid-cols-2 gap-2'>
                            <FormField
                                control={form.control}
                                name='country'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Country</FormLabel>
                                        <FormControl>
                                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                                                <SelectTrigger>
                                                    <SelectValue placeholder='Select a country'/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {countriesOptions}
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />

                            <FormField
                                control={form.control}
                                name='institution'
                                render={({field}) => (
                                    <FormItem className='space-y-1'>
                                        <FormLabel>Institution</FormLabel>
                                        <FormControl>
                                            <Input placeholder='Your institution' {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                )}
                            />
                        </div>

                        <FormField
                            control={form.control}
                            name='password'
                            render={({field}) => (
                                <FormItem className='space-y-1'>
                                    <FormLabel>Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput placeholder='********' {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name='confirmPassword'
                            render={({field}) => (
                                <FormItem className='space-y-1'>
                                    <FormLabel>Confirm Password</FormLabel>
                                    <FormControl>
                                        <PasswordInput placeholder='********' {...field} />
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />

                        <Button className='mt-2' disabled={isLoading}>
                            Request an Account
                        </Button>
                    </div>
                </form>
            </Form>
            <div>
                {status === 'success' && (
                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Sign up Successful</DialogTitle>
                            </DialogHeader>
                            <p>Please check your email inbox for a verification message. If you don’t see it, check your spam or junk folder in case it was filtered there. Follow the instructions in the email to verify your account. Once your email is verified, we will review your account for approval.</p>
                        </DialogContent>
                    </Dialog>
                )}

                {status === 'error' && (
                    <Dialog open={open} onOpenChange={setOpen}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Sign up Failed</DialogTitle>
                            </DialogHeader>
                            <p>Failed to create your account. Please try again. For any assistance, you can reach out to us at {' '}
                                <a
                                    href="mailto:mcdcadmin@clemson.edu"
                                    className="underline font-bold text-blue-300"
                                >
                                    mcdcadmin@clemson.edu
                                </a>.
                            </p>
                        </DialogContent>
                    </Dialog>
                )}
            </div>
        </div>
    )
}
