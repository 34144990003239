import { NavItem } from "@/types";

export type User = {
  id: number;
  name: string;
  company: string;
  role: string;
  verified: boolean;
  status: string;
};

export const topNavItems: NavItem[] = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: "dashboard",
    label: "Dashboard",
  },
  {
    title: "Glass Database",
    href: "/general/glass-database",
    icon: "database",
    label: "database",
  },
  {
    title: "ML Models",
    href: "/general/ml-models",
    icon: "package",
    label: "GlassPy",
  },
];

export const bottomNavItems: NavItem[] = [
  {
    title: "Glass Database",
    href: "/admin/glass-database/update",
    icon: "databaseBackup",
    label: "Manage Glass Database",
  },
  {
    title: "Jobs queue",
    href: "/admin/jobs-queue",
    icon: "iconBuildingFactory",
    label: "Jobs Queue",
  },
  {
    title: "Users",
    href: "/admin/users",
    icon: "userCog",
    label: "Manage Users",
  },
  // {
  //   title: 'Mange News',
  //   href: '/admin/notifications',
  //   icon: "newsPaper",
  //   label: "Notifications"
  // },
];

export const footerNavItems: NavItem[] = [
  // {
  //   title: 'Notifications',
  //   href: '/others/notifications',
  //   icon: "iconBell",
  //   label: "Notifications"
  // },
  {
    title: "Help Center",
    href: "/others/contact-us",
    icon: "help",
    label: "Help Center",
  },
  {
    title: "Sign Out",
    href: "/sign-out",
    icon: "logOut",
    label: "Sign Out",
  },
];
