import { Link } from 'react-router-dom'
import { Card } from '@/components/ui/card'
import AuthLayout from '../auth-layout'
import { SignUpForm } from './components/sign-up-form'
import {Separator} from "@/components/ui/separator";

export default function SignUp() {
  return (
    <AuthLayout>
      <Card className='p-6'>
        <div className='mb-2 flex flex-col space-y-2 text-center'>
          <h1 className='text-2xl font-bold tracking-tight'>
            Request an account
          </h1>
          <p className='text-sm text-muted-foreground'>
            Enter your email and password to request an account. <br/>
          </p>
        </div>
        <SignUpForm/>
        <div className='mb-4 flex flex-col text-center'>
          <p className='text-sm text-muted-foreground'>
            Already have an account?{' '}
            <Link
                to='/sign-in'
                className='underline underline-offset-4 hover:text-primary'
            >
              Sign In
            </Link>
          </p>
        </div>
        <Separator  />
        <p className='mt-4 px-8 text-center text-sm text-muted-foreground'>
          By creating an account, you agree to our{' '}
          <a
              href='/terms'
              className='underline underline-offset-4 hover:text-primary'
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
              href='/privacy'
              className='underline underline-offset-4 hover:text-primary'
          >
            Privacy Policy
          </a>
          .
        </p>
      </Card>
    </AuthLayout>
  )
}
