import {
  IconCheckbox,
  IconCircle,
  IconCircleCheck,
  IconCircleX,
  IconError404,
  IconExclamationCircle,
  IconProgress,
  IconStopwatch,
} from '@tabler/icons-react'

export const statusTypes = [
  {
    label: 'in-progress',
    value: 0,
    icon: IconProgress,
  },
  {
    label: 'finished',
    value: 1,
    icon: IconCheckbox,
  },
  {
    label: 'failed',
    value: 2,
    icon: IconError404,
  },
] as const

export const statuses = [
  {
    value: 'backlog',
    label: 'Backlog',
    icon: IconExclamationCircle,
  },
  {
    value: 'pending',
    label: 'Todo',
    icon: IconCircle,
    color: 'text-orange-700'
  },
  {
    value: 'in-progress',
    label: 'In Progress',
    icon: IconStopwatch,
    color: "text-blue-700"
  },
  {
    value: 'finished',
    label: 'Done',
    icon: IconCircleCheck,
    color: "text-green-700"
  },
  {
    value: 'failed',
    label: 'Canceled',
    icon: IconCircleX,
    color: 'text-red-700'
  },
]