import { useState, useEffect } from 'react';
import { Main } from '@/components/layout/main';
import { columns } from './components/jobs-queue-columns';
import { JobsQueueTable } from './components/jobs-queue-table';
import BreadCrumb from '@/components/breadcrumb';
import { useAdminManager } from '@/hooks/use-admin-hook';
import { userListSchema } from './data/schema';
import {useDatabaseManager} from "@/hooks/use-database-hook";

const breadcrumbItems = [
    { title: 'Admin', link: '/dashboard/jobs-queue' },
    { title: 'Queue', link: '/dashboard/jobs-queue' },
];

export default function JobsQueuePage() {

    const [jobsData, setJobsData] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState({});
    const [sorting, setSorting] = useState([]);
    const [searchKey, setSearchKey] = useState('');

    const { data, isFetching } = useDatabaseManager().useFetchJobs(0, limit, page)

    useEffect(() => {
        try {
            if (data && data.data && data.data.items) {
                setJobsData(data.data.items);
            } else {
                setJobsData([]);
            }
        } catch (e) {
            console.error(e);
        }
    }, [data]);

    return (
        <Main>
            <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
                <BreadCrumb items={breadcrumbItems}/>
            </div>
            <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap'>
                <div>
                    <h2 className='text-2xl font-bold tracking-tight'>Queue</h2>
                    <p className='text-muted-foreground'>
                        See here all of your submitted tasks for updating databases.
                    </p>
                </div>
            </div>
            <div className="-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0">
                <JobsQueueTable
                    data={jobsData}
                    columns={columns()}
                    isLoading={isFetching}
                    pagination={{
                        page,
                        limit,
                        total: data?.data?.pagination?.total_items || 0,
                        onPageChange: setPage,
                        onLimitChange: setLimit,
                    }}
                    onFiltersChange={setFilters}
                    onSortingChange={setSorting}
                    onSearchChange={setSearchKey}
                    searchKey={searchKey}
                />
            </div>
        </Main>
    );
}
