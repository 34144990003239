import { useState, useEffect } from 'react';
import { Main } from '@/components/layout/main';
import { columns } from './components/users-columns';
import { UsersTable } from './components/users-table';
import BreadCrumb from '@/components/breadcrumb';
import { useAdminManager } from '@/hooks/use-admin-hook';
import { userListSchema } from './data/schema';

const breadcrumbItems = [{ title: 'User', link: '/dashboard/user' }];

export default function Users() {
    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [filters, setFilters] = useState({});
    const [sorting, setSorting] = useState([]);
    const [searchKey, setSearchKey] = useState('');

    const { useFetchUsers } = useAdminManager();
    const { data, isFetching, refetch } = useFetchUsers({
        page,
        limit,
        filters,
        sorting,
        searchKey,
    });

    useEffect(() => {
        try {
            if (data && data.data && data.data.items) {
                setUserList(userListSchema.parse(data.data.items));
            } else {
                setUserList([]);
            }
        } catch (e) {
            console.error(e);
        }
    }, [data]);

    return (
        <Main>
            <div className='-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0'>
                <BreadCrumb items={breadcrumbItems}/>
            </div>
            <div className='mb-2 flex items-center justify-between space-y-2 flex-wrap'>
                <div>
                    <h2 className='text-2xl font-bold tracking-tight'>User List</h2>
                    <p className='text-muted-foreground'>
                        Manage your users and their roles here.
                    </p>
                </div>
            </div>
            <div className="-mx-4 flex-1 overflow-auto px-4 py-1 lg:flex-row lg:space-x-12 lg:space-y-0">
                <UsersTable
                    data={userList}
                    columns={columns(refetch)}
                    isLoading={isFetching}
                    pagination={{
                        page,
                        limit,
                        total: data?.data?.pagination?.total_items || 0,
                        onPageChange: setPage,
                        onLimitChange: setLimit,
                    }}
                    onFiltersChange={setFilters}
                    onSortingChange={setSorting}
                    onSearchChange={setSearchKey}
                    searchKey={searchKey}
                />
            </div>
        </Main>
    );
}
