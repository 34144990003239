import axios from "axios";
import pako from "pako";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.defaults.withCredentials = true;

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    const accessToken = localStorage.getItem("access_token");

    if (!refreshToken || !accessToken) {
        throw new Error("Tokens are missing");
    }

    // Call the refresh token API
    const { data: response } = await axiosInstance.post(
        '/api/2.0/auth/refresh-token',
        {
            access_token: accessToken,
            refresh_token: refreshToken,
        }
    );

    if (response?.data?.access_token) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
    } else {
        throw new Error("Failed to refresh access token");
    }
};


//Axios request interceptor to add the access token to every request
axiosInstance.interceptors.request.use((req: any) => {

    const accessToken = localStorage.getItem('access_token') || "";

    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    }

    return req;
});


// Axios response interceptor to handle 401 Unauthorized and refresh the token
axiosInstance.interceptors.response.use(
    (response) => {
        return response
    }, async (error) => {

        const originalRequest = error.config;

        // If the request returns 401 Unauthorized
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            try {

                // Update the access token in local storage
                await refreshAccessToken();

                // Retry the original request with the new access token
                originalRequest.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;

                return await axios({
                    ...originalRequest,
                    headers: originalRequest.headers.toJSON()
                });

                //return axios(originalRequest);
            } catch (refreshError) {
                handleServerError();
            }
        }

        return Promise.reject(error);
    }
);

// Add a request interceptor for POST + X-Compress
axiosInstance.interceptors.request.use((config) => {
    const isPost = config.method?.toLowerCase() === "post";
    const shouldCompress = Boolean(config.headers?.["X-Compress"]);

    if (isPost && shouldCompress) {
        // 1. Convert `config.data` to a string (if it’s an object).
        const dataString =
            typeof config.data === "object" ? JSON.stringify(config.data) : String(config.data);

        // 2. Gzip the string
        const compressedData = pako.gzip(dataString);

        // 3. Replace the request data with the compressed binary
        config.data = compressedData;

        // 4. Update the headers
        // - Must tell the server we're sending gzip'd binary
        config.headers["Content-Type"] = "application/octet-stream";

        delete config.headers["X-Compress"];
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});


// Function to redirect to login page
// Function to handle server error or network issues
function handleServerError() {
    localStorage.clear();
    if (window.location.pathname !== '/dashboard') {
        window.location.pathname = '/sign-in';
    }
}


export default axiosInstance;
