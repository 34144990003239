import React, { useEffect, useState } from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import { Card, CardContent } from "@/components/ui/card";
import { Combobox } from "@/components/combo-box";
import { LoadingSpinner } from "@/components/loader/spinner";

ChartJS.register(CategoryScale, LinearScale, PointElement, Title, Tooltip, Legend);

interface ScatterChartProps {
    oxides: { oxide_name: string, min_value?: number; max_value?: number }[];
    properties: { property_name: string, min_value?: number; max_value?: number }[];
    xAxis: string;
    yAxis: string;
    setXAxis: (xAxis: string) => void;
    setYAxis: (yAxis: string) => void;
    binSize: number; // Initial bin size value
    setBinSize: (binSize: number) => void; // Function to update bin size
    totalPoints: number; // Total points
    databaseGraphData: { x_value: number; y_value: number; count: number }[];
    isGraphDataFetching: boolean;
}

const MAX_RADIUS = 10; // Set a maximum radius value for points


const ScatterChart: React.FC<ScatterChartProps> = ({
                                                       oxides,
                                                       properties,
                                                       xAxis,
                                                       yAxis,
                                                       setXAxis,
                                                       setYAxis,
                                                       binSize,
                                                       setBinSize,
                                                       totalPoints,
                                                       databaseGraphData,
                                                       isGraphDataFetching,
                                                   }) => {

    const [calculatedBinSize, setCalculatedBinSize] = useState(binSize);

    // Calculate dynamic bin size based on x and y ranges
    useEffect(() => {
        if (databaseGraphData.length > 0) {
            const xValues = databaseGraphData.map((item) => item.x_value);
            const yValues = databaseGraphData.map((item) => item.y_value);

            const xRange = Math.max(...xValues) - Math.min(...xValues);
            const yRange = Math.max(...yValues) - Math.min(...yValues);
            const range = Math.max(xRange, yRange); // Use the larger range for consistency

            const dynamicBinSize = range / 50; // Adjust 50 bins as a default granularity
            setCalculatedBinSize(dynamicBinSize);

        }
    }, [databaseGraphData, binSize]);

    const combinedOptions = [
        ...oxides.map((oxide) => ({
            label: oxide.oxide_name,
            value: oxide.oxide_name,
            min: oxide.min_value,
            max: oxide.max_value,
        })),
        ...properties.map((property) => ({
            label: property.property_name,
            value: property.property_name,
            min: property.min_value,
            max: property.max_value,
        })),
    ];

    const getColorForCount = (count: number, maxCount: number) => {
        const ratio = count / maxCount;
        const red = Math.floor(255 * (1 - ratio));
        const green = Math.floor(255 * ratio);
        return `rgb(${red}, ${green}, 128)`;
    };

    const maxCount = Math.max(...databaseGraphData.map((item) => item.count || 0), 1);

    const chartData = {
        datasets: [
            {
                label: `${xAxis} vs ${yAxis}`,
                data: databaseGraphData.map((item) => ({
                    x: item.x_value,
                    y: item.y_value,
                    r: Math.min(Math.max(Math.sqrt(item.count || 1) * 4, 6), MAX_RADIUS), // Ensure radius is capped at MAX_RADIUS
                })),
                backgroundColor: databaseGraphData.map((item) =>
                    getColorForCount(item.count, maxCount)
                ),
                borderColor: "rgba(0, 0, 0, 0.1)",
            },
        ],
    };


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: { font: { size: 16 } },
            },
            // title: {
            //     display: true,
            //     text: `${xAxis} vs ${yAxis}`,
            //     font: { size: 16 },
            // },
            tooltip: {
                callbacks: {
                    label: (context: any) => {
                        const { x, y, r } = context.raw; // Extract x, y, and r (radius) from raw
                        // Reverse calculate count from radius. Use Math.min and the same factor for consistency.
                        const count = Math.pow(Math.min(r, MAX_RADIUS) / 4, 2); // Use MAX_RADIUS to reverse scale the count correctly
                        return `X: ${x}, Y: ${y}, Count: ${count.toFixed(0)}`;
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: xAxis || "X-Axis",
                    font: { size: 16 },
                },
            },
            y: {
                title: {
                    display: true,
                    text: yAxis || "Y-Axis",
                    font: { size: 16 },
                },
            },
        },
        elements: {
            point: {
                radius: (context) => {
                    return context.raw?.r; // Use `r` as the radius or fallback to a default
                },
            },
        },
    };


    return (
        <Card className="shadow-md flex flex-col justify-between items-center pr-2">
            <CardContent>
                <div className="flex gap-4 items-center justify-center m-4">
                    <p>X-Axis</p>
                    <Combobox
                        frameworks={combinedOptions.filter((option) => option.value !== yAxis)}
                        value={xAxis}
                        setValue={setXAxis}
                        placeholder="Choose X-Axis"
                    />
                    <p>Y-Axis</p>
                    <Combobox
                        frameworks={combinedOptions.filter((option) => option.value !== xAxis)}
                        value={yAxis}
                        setValue={setYAxis}
                        placeholder="Choose Y-Axis"
                    />
                </div>
                <div className="m-4">
                    <div className="flex flex-row gap-2 items-center justify-center">
                        <p className="text-sm font-bold">Bin Size:</p> <input
                        type="range"
                        min={0} // Allow 0 for unbinned data
                        max={calculatedBinSize * 5}
                        step={calculatedBinSize / 100}
                        value={binSize}
                        onChange={(e) => setBinSize(parseFloat(e.target.value))}
                    /><p
                        className="font-light text-xs">{binSize.toFixed(2)} (Suggested: {calculatedBinSize.toFixed(2)})</p>
                        <p className="text-sm font-bold">Total Points:</p> <p
                        className="font-light text-xs">{totalPoints}</p>
                    </div>
                </div>
                {isGraphDataFetching ? (
                    <LoadingSpinner/>
                ) : (
                    <div className="h-96 w-full">
                        <Scatter data={chartData} options={options} />
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

export default ScatterChart;
