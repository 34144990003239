import { ColumnDef } from "@tanstack/react-table";

export const generateDynamicColumns = (transformedData: any[]): ColumnDef<any, any>[] => {
  const dynamicColumns: ColumnDef<any, any>[] = [];

  dynamicColumns.push({
    accessorKey: "composition",
    header: "Composition",
    cell: ({ getValue }) => {
      const composition = JSON.parse(getValue() as string); // Parse JSON string back to object
      return (
          <div className="flex flex-row gap-4">
            {Object.entries(composition).map(([key, value]) => (
                <div key={key} className="flex gap-1">
                  <span className="font-semibold">{`{`}</span>
                  <span className="font-semibold">{key}</span>
                  <span className="font-semibold">:</span>
                  <span>{value}</span>
                  <span className="font-semibold">{`}`}</span>
                </div>
            ))}
          </div>
      );
    },
  });

  if (transformedData.length > 0) {
    Object.keys(transformedData[0]).forEach((key) => {
      if (key !== "composition") {
        dynamicColumns.push({
          accessorKey: key,
          header: key,
        });
      }
    });
  }

  return dynamicColumns;
};