export function SnakeToCamelCase(key: string): string {
  return key.replace(/_([a-z])/g, (_, char) => char.toUpperCase());
}

export function TransformToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map(TransformToCamelCase);
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = SnakeToCamelCase(key);
      acc[camelKey] = TransformToCamelCase(obj[key]);
      return acc;
    }, {} as any);
  }
  return obj;
}

function ExtractElementsFromOxide(oxide) {
  const regex = /([A-Z][a-z]*)(\d*)([+-]?)/g; // Matches element, quantity, and charge
  let matches;
  let elements = [];

  while ((matches = regex.exec(oxide)) !== null) {
    let element = matches[1];

    // Trim charge symbols ('+' or '-') and convert to lowercase if present
    if (matches[3] !== "") {
      element = element.toLowerCase();
    }

    // Capitalize the first letter and ensure the rest are lowercase
    element = element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();

    elements.push(element);
  }

  return elements;
}

export const PreprocessOxides = (oxides) => {
  const oxideMap = {};

  (oxides || []).forEach((oxide) => {
    const elements = ExtractElementsFromOxide(oxide.oxide_name); // Extract elements
    elements.forEach((element) => {
      const lowerCaseElement = element.toLowerCase(); // Convert element to lowercase
      if (!oxideMap[lowerCaseElement]) {
        oxideMap[lowerCaseElement] = [];
      }
      oxideMap[lowerCaseElement].push(oxide);
    });
  });

  return oxideMap;
};
