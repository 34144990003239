import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { DataTableFacetedFilter } from './data-table-faceted-filter';
import { DataTableViewOptions } from './data-table-view-options';

interface DataTableToolbarProps<TData> {
    table: Table<TData>;
    searchKey: string;
    onSearchChange: (value: string) => void;
    onFilterChange: (filters: Record<string, string | number>) => void;
}

export function DataTableToolbar<TData>({
                                            table,
                                            searchKey,
                                            onSearchChange,
                                            onFilterChange,
                                        }: DataTableToolbarProps<TData>) {

    const isFiltered = table.getState().columnFilters.length > 0;

    const handleFilterChange = (columnId: string, value: string) => {
        onFilterChange({"approval_status": value});
    };

    const resetFilters = () => {
        table.resetColumnFilters(); // Reset all filters in the table
        onFilterChange({}); // Notify the parent about the reset
    };

    return (
        <div className="flex items-center justify-between">
            <div className="flex flex-1 flex-col-reverse items-start gap-y-2 sm:flex-row sm:items-center sm:space-x-2">
                {/* Search Input */}
                <Input
                    placeholder="Search by name or email..."
                    value={searchKey}
                    onChange={(event) => onSearchChange(event.target.value)}
                    className="h-8 w-[150px] lg:w-[250px]"
                />

                {/* Faceted Filters */}
                <div className="flex gap-x-2">
                    {table.getColumn('approval_status') && (
                        <DataTableFacetedFilter
                            column={table.getColumn('approval_status')}
                            title="Approval status"
                            options={[
                                { label: 'Requested', value: '1' },
                                { label: 'Approved', value: '2' },
                                { label: 'Declined', value: '3' },
                            ]}
                            onFilterChange={(value: any) => handleFilterChange('approval_status', value)}
                        />
                    )}
                </div>

                {/* Reset Filters Button */}
                {isFiltered && (
                    <Button
                        variant="ghost"
                        onClick={resetFilters}
                        className="h-8 px-2 lg:px-3"
                    >
                        Reset
                        <Cross2Icon className="ml-2 h-4 w-4" />
                    </Button>
                )}
            </div>

            {/* View Options */}
            <DataTableViewOptions table={table} />
        </div>
    );
}
