import { DashboardNav } from "@/components/dashboard-nav";
import { topNavItems, bottomNavItems, footerNavItems } from "@/constants/data";
import { cn } from "@/lib/utils";
import * as React from "react";

export default function Sidebar() {
  return (
      <nav className={cn("relative h-screen border-r hidden lg:block w-50")}>
        <div className="space-y-4 py-4">
          <div className="px-3 py-2">
            <div className="space-y-1">
              <h2 className="mb-2 px-4 text-md font-semibold tracking-tight">
                General
              </h2>
              <DashboardNav items={topNavItems} />
            </div>
          </div>
        </div>
        {localStorage.getItem("is_admin") === "true" && (<div className="space-y-4 py-4">
          <div className="px-3 py-2">
              <div className="space-y-1">
                  <h2 className="mb-2 px-4 text-md font-semibold tracking-tight">
                      Admin
                  </h2>
                  <DashboardNav items={bottomNavItems}/>
              </div>
            </div>
          </div>)}
          <div className="absolute bottom-14 space-y-4 py-4">
              <div className="px-3 py-2">
                  <div className="space-y-1">
                      <h2 className="mb-2 px-4 text-md font-semibold tracking-tight">
                          Others
                      </h2>
                      <DashboardNav items={footerNavItems}/>
                  </div>
              </div>
        </div>
        {/*<div className="absolute bottom-12 w-full px-3 py-4 border-t">*/}
        {/*  <button*/}
        {/*      className="w-full px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded-md hover:bg-red-600"*/}
        {/*      onClick={() => localStorage.clear()}*/}
        {/*  >*/}
        {/*    Log Out*/}
        {/*  </button>*/}
        {/*</div>*/}
      </nav>
  );
}
