import { Link } from 'react-router-dom'
import { Card } from '@/components/ui/card'
import AuthLayout from '../auth-layout'
import { ResetForm } from './components/reset-password-form'

export default function ResetPassword() {
  return (
    <AuthLayout>
      <Card className='p-6'>
        <div className='mb-2 flex flex-col space-y-2 text-center'>
          <h1 className='text-2xl font-bold tracking-tight'>
            Reset Password
          </h1>
          <p className='text-sm text-muted-foreground'>
            Enter your new password.
          </p>
        </div>
        <ResetForm />
        <p className='mt-4 px-8 text-center text-sm text-muted-foreground'>
          Don't have an account?{' '}
          <Link
            to='/sign-up'
            className='underline underline-offset-4 hover:text-primary'
          >
            Sign up
          </Link>
          .
        </p>
      </Card>
    </AuthLayout>
  )
}
