import React, { useEffect, useState } from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { toast } from '@/hooks/use-toast';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription,
} from '@/components/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { DataTable } from './data-table';
import { useDatabaseManager } from '@/hooks/use-database-hook';
import { LoadingSpinner } from "@/components/loader/spinner";
import {useNavigate} from "react-router-dom";

const fileSchema = z.object({
    file: z
        .instanceof(FileList)
        .refine((files) => files.length > 0, {
            message: 'Please upload a file',
        })
        .refine(
            (files) => ['text/csv', 'text/plain'].includes(files?.[0]?.type),
            {
                message: 'Please upload a CSV or plain text file',
            }
        ),
});

const rangeSchema = z.object({
    composition: z.string().optional(),
    properties: z.string().optional(),
    meta_data: z.string().optional(),
});

export function FileUploaderCard() {

    const [step, setStep] = useState(1);
    const [tasks, setTasks] = useState([]);
    const [columns, setColumns] = useState([]);
    const [objectName, setObjectName] = useState('');

    const navigate = useNavigate();

    const [fileDetails, setFileDetails] = useState(null);

    const [glassDatabaseID, setGlassDatabaseID] = useState(0);

    const fileForm = useForm({
        resolver: zodResolver(fileSchema),
        defaultValues: { file: undefined },
    });

    const rangeForm = useForm({
        resolver: zodResolver(rangeSchema),
        defaultValues: {
            composition: '',
            properties: '',
            meta_data: '',
        },
    });

    const { data: databaseList } = useDatabaseManager().useFetchDatabase(
        Number(localStorage.getItem('id'))
    );
    const { mutate: mutateGlassDatabaseDataJob, isLoading } =
        useDatabaseManager().useMutateGlassDatabaseDataJob();

    const { mutate: uploadFile, isLoading: isUploadLoading, data: gcpData } = useDatabaseManager().useUploadFileToGCS();

    useEffect(() => {
        if (
            databaseList &&
            databaseList.data &&
            Array.isArray(databaseList.data) &&
            databaseList.data.length > 0
        ) {
            setGlassDatabaseID(databaseList.data[0].id);
        }
    }, [databaseList]);

    const fileRef = fileForm.register('file');

    const parseCSV = (csvContent: string) => {
        const lines = csvContent.split('\n').filter(Boolean);
        const headers = lines[0].split(',').map((h) => h.trim());
        const data = lines.slice(1).map((line) => {
            const values = line.split(',').map((v) => v.trim());
            return headers.reduce((acc: any, header, index) => {
                acc[header] = values[index] || '';
                return acc;
            }, {});
        });
        const dynamicColumns = headers.map((header) => ({
            accessorKey: header,
            header: header,
        }));
        setTasks(data);
        setColumns(dynamicColumns);
    };

    const handleFileUpload = (formValues: any) => {
        const fileList = formValues.file;
        if (!fileList || !fileList[0]) {
            toast({
                title: 'No file uploaded',
                description: 'Please select a file before uploading',
            });
            return;
        }
        const file = fileList[0];
        const fileDetail = {
            name: file.name,
            size: `${(file.size / 1024).toFixed(2)} KB`,
            type: file.type,
        };
        setFileDetails(fileDetail);

        // 1. Upload file to GCS
        uploadFile(file, {
            onSuccess: (data : {
                success: boolean,
                gcsUrl: string,
                objectName: string
            }) => {
                // 2. Parse the CSV locally (no need to re-fetch from GCS if we have the file)
                const reader = new FileReader();
                reader.onload = (e) => {
                    const csvContent = e.target?.result;
                    if (typeof csvContent === 'string') {
                        try {
                            parseCSV(csvContent);
                            setStep(2);
                            toast({
                                title: 'File uploaded & parsed successfully!',
                                description: 'Proceed to Step 2.',
                            });
                        } catch (error) {
                            toast({
                                title: 'Parsing failed!',
                                description: 'Error processing the file.',
                            });
                        }
                    }
                };
                reader.readAsText(file);
                setObjectName(data.objectName)
            },
            onError: (error) => {
                toast({
                    title: 'Upload failed',
                    description: String(error),
                });
            },
        });
    };

    const parseRange = (range: string) => {
        if (!range) return [];
        return range.split(',').flatMap((part) => {
            const trimmed = part.trim();
            if (trimmed.includes('-')) {
                const [start, end] = trimmed.split('-').map((num) => parseInt(num.trim(), 10));
                if (isNaN(start) || isNaN(end) || start > end) return [];
                return Array.from({ length: end - start + 1 }, (_, i) => start + i);
            } else {
                const num = parseInt(trimmed, 10);
                return isNaN(num) ? [] : [num];
            }
        });
    };

    const handleSave = React.useCallback(() => {
        const composition = parseRange(rangeForm.getValues('composition') || '');
        const properties = parseRange(rangeForm.getValues('properties') || '');
        const allColumnIndices = columns.map((_, idx) => idx + 1);
        const usedIndices = [...composition, ...properties];
        const meta_data = allColumnIndices.filter((idx) => !usedIndices.includes(idx));

        const payload = {
            compositions: columns.filter((_, idx) => composition.includes(idx + 1)).map(col => col.accessorKey),
            properties: columns.filter((_, idx) => properties.includes(idx + 1)).map(col => col.accessorKey),
            meta_data: columns.filter((_, idx) => meta_data.includes(idx + 1)).map(col => col.accessorKey),
        };

        mutateGlassDatabaseDataJob(
            {
                database_id: glassDatabaseID,
                compositions: payload.compositions,
                properties: payload.properties,
                meta_data: payload.meta_data,
                object_name: objectName,
                user_id: Number(localStorage.getItem('id'))
            },
            {
                onSuccess: () => {
                    toast({
                        title: 'Task added',
                        description: 'The task has been added successfully!',
                    });
                    navigate("/admin/jobs-queue")
                },
                onError: () => {
                    toast({
                        variant: 'destructive',
                        title: 'Data update failed',
                        description: 'Failed to create the task',
                    });
                },
            }
        );
    }, [columns, tasks, glassDatabaseID, mutateGlassDatabaseDataJob, rangeForm]);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Step {step === 1 ? '1: Upload File' : '2: Parse and Analyze'}</CardTitle>
                <CardDescription>
                    {step === 1
                        ? 'Upload a CSV file.'
                        : 'Set column ranges and save to the database.'}
                </CardDescription>
            </CardHeader>
            <CardContent>
                {step === 1 && (
                    <Form {...fileForm}>
                        <form onSubmit={fileForm.handleSubmit(handleFileUpload)}>
                            <FormField
                                control={fileForm.control}
                                name="file"
                                render={() => (
                                    <FormItem className="space-y-1 mb-4">
                                        <FormLabel>File</FormLabel>
                                        <FormControl>
                                            <Input type="file" accept=".csv, .txt" {...fileRef} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <div className="flex justify-end gap-2">
                                <Button type="submit" disabled={isUploadLoading}>
                                    {isUploadLoading ? (
                                        <>
                                            Uploading...
                                            <LoadingSpinner />
                                        </>
                                    ) : (
                                        'Next'
                                    )}
                                </Button>
                            </div>
                        </form>
                    </Form>
                )}

                {step === 2 && (
                    <>
                        <div className="flex justify-between items-center flex-wrap gap-2 w-full">
                            <Form {...rangeForm}>
                                <form className="flex items-end gap-4 mb-4 flex-wrap">
                                    <FormField
                                        control={rangeForm.control}
                                        name="composition"
                                        render={({ field }) => (
                                            <FormItem className="w-36">
                                                <FormLabel>Composition</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="e.g., 1-2" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                    <FormField
                                        control={rangeForm.control}
                                        name="properties"
                                        render={({ field }) => (
                                            <FormItem className="w-36">
                                                <FormLabel>Properties</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="e.g., 3-5" {...field} />
                                                </FormControl>
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                    />
                                </form>
                            </Form>
                            <div className="flex flex-1 gap-2 mb-4 mt-8">
                                <select
                                    value={glassDatabaseID}
                                    className="capitalize border rounded-md px-2 py-1.5"
                                    onChange={(e) => setGlassDatabaseID(Number(e.target.value))}
                                >
                                    {databaseList &&
                                        databaseList.data &&
                                        Array.isArray(databaseList.data) &&
                                        databaseList.data.map((db: any) => (
                                            <option key={db.id} value={db.id} style={{ fontWeight: '300' }}>
                                                {db.name}
                                            </option>
                                        ))}
                                </select>
                                <Button className="px-4" onClick={handleSave} disabled={isLoading}>
                                    {isLoading ? (
                                        <>
                                            Saving...
                                            <LoadingSpinner />
                                        </>
                                    ) : (
                                        'Save'
                                    )}
                                </Button>
                            </div>
                            <div className="flex justify-between mt-8 mb-4">
                                <Button variant="outline" onClick={() => setStep(1)}>
                                    Previous
                                </Button>
                            </div>
                        </div>
                        <DataTable data={tasks} columns={columns} ranges={{}} />
                    </>
                )}
            </CardContent>
        </Card>
    );
}
