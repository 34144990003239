import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

interface RouteComponentProps {
    children?: ReactNode;
}

const AdminRoutes: React.FC<RouteComponentProps> = ({ children }) => {
    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('is_admin') === 'true';

        if (!isLoggedIn) {
            navigate('/dashboard', { replace: true });
        }
    }, [navigate]);

    return <>{children}</>;
};

export default AdminRoutes;
